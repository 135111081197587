.circles-faqs {
  .accordion-item {
    background-color: transparent !important;
    border: none !important;
  }

  .accordion-button {
    color: $black;
    background-color: transparent !important;
    box-shadow: none !important;
    padding: 0.75rem 0rem !important;
    &::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23BF9000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
    }
    &:not(.collapsed)::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23BF9000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
      transform: rotate(-180deg) !important;
      align-self: center !important;
    }
  }
  .view-more-toggle {
    display: flex;
    align-items: center;
    color: map-get($saffron, '700');
    border: none;
    &:focus {
      color: map-get($saffron, '700');
    }
    .accordion-icon {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23BF9000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      width: 1.25rem;
      height: 1.25rem;
      margin-left: 0.5rem;
      display: inline-block;
      transform: rotate(0deg);
      transition: transform 0.3s;
    }
    .rotated {
      transform: rotate(-180deg);
    }
  }
}
.select-coach-circles {
  border-radius: 0.313rem;
  background: map-get($gray, '100');
}

.circle-detail-box {
  border: 2px solid map-get($gray, '502') !important;
}

.faqs-accordion {
  padding: 1.5rem !important;
  border: 2px solid map-get($gray, '502') !important;
  border-radius: 0.313rem !important;
  .accordion-item {
    background-color: transparent !important;
  }
}
.circles-faqs .s6a {
  font-weight: 400;
}
.date-time-box {
  border: 1px solid map-get($gray, '502');
  border-radius: 0.313rem;
  color: $black;
}
.date-box {
  background-color: map-get($gray, '103');
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  color: $black;
  .date-color {
    color: $abroad-blue;
  }
  .day-color {
    color: #636e7b;
  }
}
.circle-checkbox {
  border: 1px solid $black;
}
.circle-checkbox:focus {
  border: 1px solid $black !important;
  outline: none !important;
  box-shadow: none !important;
}
.selected-box {
  border: 1px solid $abroad-blue;
  background-color: $abroad-blue;
  .date-color {
    color: $white;
  }
}
.choose-circle-input:checked {
  background-color: map-get($green, '100') !important;
  border-color: map-get($green, '100') !important;
}
.choose-circle-input:focus {
  border-color: map-get($green, '100') !important;
  outline: none !important;
  box-shadow: none !important;
}
