.header-tabs {
  flex-wrap: nowrap;
  overflow: auto;
  margin-top: 1rem;
  @include tabs-horizontal-scroll;
  .nav-item {
    .nav-link {
      text-transform: uppercase;
      color: $black;
      padding: 0.625rem 1.5rem;
      text-align: center;
      margin-right: 1rem;
      border: 1px solid $black;
      border-radius: 0.313rem;
      opacity: 1;
      @extend .s9;
      &.active,
      &:hover {
        color: $white;
        background-color: $abroad-blue;
        border: 1px solid $abroad-blue !important;
      }
      &:focus-visible,
      &.active {
        outline: none !important;
      }
      &:disabled,
      &.disabled {
        opacity: 0.5 !important;
        pointer-events: none;
      }
    }
  }
}

.outline-tabs {
  flex-wrap: nowrap;
  overflow: auto;
  margin-top: 1rem;
  @include tabs-horizontal-scroll;
  .nav-item,
  .tab-item {
    .nav-link,
    .custom-tab {
      text-transform: capitalize;
      color: $black;
      padding: 0.5rem 1rem;
      text-align: center;
      margin-right: 0.5rem;
      border: 1px solid $black;
      border-radius: 1.375rem;
      opacity: 1;
      @extend .s11;
      &.active,
      &:hover {
        color: $white;
        background-color: $abroad-blue;
        border: 1px solid $black !important;
      }
      &:focus-visible,
      &.active {
        outline: none !important;
      }
      &:disabled,
      &.disabled {
        opacity: 0.5 !important;
        pointer-events: none;
      }
    }
  }
}

@media only screen and (max-width: 300px) {
  .coach-recommends-tabs {
    .dropdown-toggle,
    .nav-link {
      padding: 0.5rem 0.625rem !important;
      font-size: 0.7rem !important;
    }
  }
}
