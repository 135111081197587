aside {
  z-index: 100 !important;
}

.sidebar-wrapper {
  background-color: $abroad-blue;
  width: 100%;
  margin-left: 0rem;
  letter-spacing: 0.02rem;
  overflow: none;
  -ms-overflow-style: none; /* IE and Edge */

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    background-color: transparent;
    width: 8px;
  }

  /* scrollbar itself */
  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
  }

  /* set button(top and bottom of the scrollbar) */
  ::-webkit-scrollbar-button {
    display: none;
  }

  @include transition(margin 0.25s ease-out);
  .nav-item {
    a {
      color: rgba(255, 255, 255, 0.6);
    }
    a {
      @include transition-all;
      .nav-link-icon {
        vertical-align: middle;
        letter-spacing: normal;
      }
      &:hover {
        color: $white;
      }
      &.active {
        color: map-get($saffron, '300');
        & {
          .icon:before {
            color: map-get($saffron, '300');
          }
        }
      }
    }
  }
  .angle-down-icon,
  .angle-left-icon {
    &.active path {
      stroke: map-get($saffron, '300');
    }
  }
  .sidebar-section {
    background-color: $abroad-blue;
  }
}
@include media-breakpoint-up(lg) {
  #content {
    margin-top: 0rem;
    width: 100%;
  }
  .sidebar-wrapper {
    margin-left: 0rem;
    &.hide {
      margin-left: -13.75rem;
    }
  }
  .sidebar-wrapper-with-play-bar {
    padding-bottom: 6rem;
  }
}

@include media-breakpoint-down(lg) {
  .sidebar-wrapper {
    padding-top: 3.7rem !important;
  }
}
@include media-breakpoint-down(sm) {
  .sidebar-wrapper {
    padding-bottom: 6.25rem;
  }
}
@include media-breakpoint-down(lg) {
  .sidebar-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    @include transition(left 0.25s ease-out);
    &.hide {
      left: -100vw;
    }
  }
  .sidebar-wrapper-with-play-bar {
    padding-bottom: 4.25rem;
  }
}

.nav-header {
  z-index: 10;
}
#sidebar .down-separator {
  border-bottom: 1px solid $line-separator-color !important;
}
.nav-link {
  padding: 1rem 0.25rem 1rem 1.5rem;
}
.office-platform-link {
  margin-left: 0.7rem;
}
#sidebar-navlink {
  @include text-decoration-none;
}
.nav-link,
.sidebar-link {
  @include text-decoration-none;
  opacity: 0.6;
  &:hover,
  &.active {
    opacity: 1;
  }
}
.show-dropdown-items {
  padding-left: 2.25rem;
}
.text-saffron-300 {
  color: map-get($saffron, '300');
}
.sidebar-wrapper {
  overflow-y: overlay !important;
  overflow-y: scroll;
  overflow-x: hidden !important;
}
.angle-left-icon {
  transition: transform 0.35s linear;
}
.angle-down-icon {
  transform: rotate(90deg);
  transition: transform 0.35s linear;
}
.show-dropdown-items {
  animation: growDown 300ms ease-in-out forwards !important;
  transform-origin: top center !important;

  @keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    80% {
      transform: scaleY(1.1);
    }
    100% {
      transform: scaleY(1);
    }
  }
}
