$valid-color: #509f92;
$invalid-color: #b22222;
$black: #000;
$white: #fff;
$abroad-blue: #0a223c;
$saffron: (
  '300': #efc030,
  '600': #d5a000,
  '700': #bf9000,
  '700-5': rgba(191, 144, 0, 0.5),
);
$gray: (
  '100': #f3f6f6,
  '101': rgba(243, 246, 246, 0.7),
  '102': rgba(243, 246, 246, 0.1),
  '103': rgba(10, 34, 60, 0.1),
  '200': #e0e7de,
  '201': #706f6f,
  '300': #f2f5f5,
  '501': #828282,
  '502': #5e6e7f,
  '800': #a0a0a0,
  '900': $black,
);
$line-separator-color: #d9d9d9;
$manager-efficacy-color: #84a07c;
$orange: (
  '100': #ce8147,
);
$blue: (
  '100': #69b1d8,
  '200': #0076bd,
  '600': #123c69,
);
$dark: (
  '400': #101010,
  '500': #212121,
  '600': #141414,
  '700': #1e1e1e,
  '800': #282828,
  '900-4': rgba(0, 0, 0, 0.4),
  '900-5': rgba(0, 0, 0, 0.5),
  '900-7': rgba(0, 0, 0, 0.7),
  '900': $black,
);

$red: (
  '100': #c73f3f,
  '800': #a71818,
);

$green: (
  '100': #509f92,
  '500': #285238,
);

$colors: map-merge(
  $colors,
  (
    black: $black,
    'black-100': #212121,
    'white': $white,
    'dark-700': #1e1e1e,
    'green-100': #509f92,
  )
);

$custom-colors: (
  'saffron': $saffron,
  'gray': $gray,
  'dark': $dark,
  'orange': $orange,
  'green': $green,
  'blue': $blue,
  'red': $red,
);

$font-sizes: (
  8: 0.5rem,
  10: 0.625rem,
  12: 0.75rem,
  14: 0.875rem,
  16: 1rem,
  18: 1.125rem,
  20: 1.25rem,
  22: 1.375rem,
  24: 1.5rem,
  26: 1.625rem,
  28: 1.75rem,
  30: 1.875rem,
  36: 2.25rem,
  40: 2.5rem,
  44: 2.75rem,
  48: 3rem,
  50: 3.125rem,
  52: 3.25rem,
);

$opacities: () !default;
$opacities: map-merge(
  (
    0: 0,
    10: 0.1,
    15: 0.15,
    25: 0.25,
    50: 0.5,
    60: 0.6,
    75: 0.75,
    1: 1,
  ),
  $opacities
);

$customsize: () !default;

$customspacers: 0.25rem;

$customsize: map-merge(
  (
    0: 0,
    1: $customspacers,
    2: (
      $customspacers * 2,
    ),
    3: (
      $customspacers * 3,
    ),
    4: (
      $customspacers * 4,
    ),
    5: (
      $customspacers * 5,
    ),
    6: (
      $customspacers * 6,
    ),
    8: (
      $customspacers * 8,
    ),
    12: (
      $customspacers * 12,
    ),
    16: (
      $customspacers * 16,
    ),
    20: (
      $customspacers * 20,
    ),
    35: (
      $customspacers * 35,
    ),
    40: (
      $customspacers * 40,
    ),
    60: (
      $customspacers * 60,
    ),
    80: (
      $customspacers * 80,
    ),
  ),
  $customsize
);
