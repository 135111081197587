.plan-payment {
  .btn {
    border-radius: 0.313rem !important;
  }
}
.user-payment {
  max-width: 424px;
  margin: 0 auto;

  .input-field-group {
    margin-bottom: 2rem;
  }

  label {
    font-family: 'Montserrat' !important;
    font-weight: 600 !important;
    color: $black !important;
    font-size: 0.625rem !important;
    line-height: 0.75rem !important;
    letter-spacing: 0.25em !important;
  }
  & .make-payment {
    height: 40px;
  }
  .form-control {
    background: $white !important;
    border: 1px solid $black !important;
    border-radius: 3px;
    font-size: 12pt;
    color: $black;
    .was-validated &:invalid,
    &.is-invalid {
      border-color: $invalid-color !important;
    }
    .was-validated &:valid,
    &.is-valid {
      border-color: $valid-color !important;
    }
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: map-get($dark, '900-5') !important;
      opacity: 0.5 !important; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: map-get($dark, '900-5') !important;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: map-get($dark, '900-5') !important;
    }
  }

  .form-control-md {
    font-family: Open Sans;
    font-weight: 600;
    font-size: 0.75rem !important;
    line-height: 1.25rem !important;
    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 5px !important;
    padding: 6px 13px;
    color: #000 !important;
    &::placeholder {
      color: #000 !important;
    }
  }
  .checkbox-wrapper {
    margin-right: 0.5rem;
  }
}

.promo-accordion {
  & .accordion > .card {
    border-radius: 0.25rem !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
  }
  .pay-card-header {
    padding: 0.75rem 1.25rem;
    background: rgba(0, 0, 0, 0.03);
  }
}

.payment-box .modal-content {
  box-shadow: 20px 20px 10px #00000029;
}
.add-card {
  .card-labels {
    margin-bottom: 0.5rem;
  }
}
.add-card .StripeElement {
  background: $white !important;
  border: 1px solid $black !important;
  padding: 0px 16px !important;
  border-radius: 3px !important;
  font-size: 12pt;
  color: $black;
  // height: 2rem;
  & ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: map-get($dark, '900-5') !important;
    opacity: 0.5 !important; /* Firefox */
  }

  & :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: map-get($dark, '900-5') !important;
    opacity: 0.5 !important;
  }

  & ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: map-get($dark, '900-5') !important;
    opacity: 0.5 !important;
  }
  &.StripeElement--invalid {
    border-color: $invalid-color !important;
  }
  &.StripeElement--invalid.StripeElement--focus {
    box-shadow: 0 0 0 0.2rem rgba(188, 76, 106, 0.25);
  }
  &.StripeElement--complete {
    border-color: $valid-color !important;
  }
  &.StripeElement--complete.StripeElement--focus {
    box-shadow: 0 0 0 0.2rem rgba(80, 159, 146, 0.25);
  }
}
.subscription-title,
.payment-history-title {
  margin-top: 3.25rem;
  margin-bottom: 1.375rem;
}
.credit-card-shimmer {
  height: 10rem !important;
}
.input-shimmer {
  & .shimmer-title-line {
    height: 2rem !important;
  }
}
.payment-details-form-wrp {
  padding: 20px 0;
}

.payment-modal {
  .modal-content {
    max-width: 29.438rem;
    border-radius: 10px;
    background: $white;
    border: none;
  }
  .payment-modal-header {
    border-radius: 5px 5px 0px 0px;
    background: $abroad-blue;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .payment-modal-title {
    font-family: Montserrat;
    font-size: 0.625rem; //10px
    font-style: normal;
    font-weight: 600;
    line-height: 1rem; //16px
    letter-spacing: 0.125rem; //2px
    color: map-get($saffron, '300');
    text-transform: uppercase;
  }
  .payment-modal-body {
    padding: 1.313rem 1.188rem 2.25rem 1.375rem;
  }
  .confirmation-title {
    font-family: Open Sans;
    font-size: 1.25rem; //20px
    font-style: normal;
    font-weight: 600;
    line-height: 1.875rem; //30px
    letter-spacing: -0.025rem; //-0.4px
  }
  .confirmation-subtitle {
    font-family: Open Sans;
    font-size: 1rem; //16px
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem; //28px
    letter-spacing: -0.02rem; //-0.32px
  }
}
.hover\:text-white {
  color: $white !important;
}
.add-card-shimmer {
  margin: 2rem;
  .shimmer-button:first-child {
    margin-right: 1.5rem;
  }
  .shimmer-button {
    margin-bottom: 0rem !important;
  }
}
.payment-form-shimmer {
  .shimmer-text {
    margin-bottom: 0.5rem;
    width: 50%;
  }
  .shimmer-button {
    width: 100% !important;
  }
}
.coaching-payment {
  .form-control-md {
    border-radius: 3px !important;
  }
  .address-input {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .payment-info {
    .total {
      font-family: Montserrat;
      font-size: 0.625rem; //10px
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.156rem; //2.5px
    }
    .amount {
      font-family: Open Sans;
      font-size: 0.875rem; //14px
      font-style: normal;
      font-weight: 700;
      line-height: 1.5rem; //24px
      letter-spacing: -0.018rem; //-0.28px
    }
  }
}
.saved-cards-shimmer {
  .shimmer-text-line {
    width: 25% !important;
  }
}
.card-listing-wrapper {
  @include gray-scrollbar;
  .card-listing {
    max-height: 16rem;
    overflow: overlay;
  }
}
@include media-breakpoint-up(lg) {
  .custom__modal {
    & .modal-dialog {
      width: 60vw !important; /* Occupy the 60% of the screen width */
      max-width: 60vw !important;
    }
  }
  .payment-details {
    margin-top: 2rem !important;
  }
}
