.btn-saffron {
  // btn-variant($bgColor, $focusBGColor, $disabledBGColor, $color)
  @include btn-variant(
    map-get($saffron, '300'),
    map-get($saffron, '700'),
    map-get($saffron, '300'),
    $black
  );
}

.btn-black {
  @include btn-variant(
    map-get($dark, '900'),
    map-get($dark, '800'),
    map-get($dark, '600'),
    $white
  );
  color: $white !important;
  line-height: normal !important;
  &:hover:enabled {
    box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.3);
  }
}

.btn-abroad-blue {
  @extend .s9;
  background-color: $abroad-blue !important;
  color: $white !important;
  text-align: center;
  padding: 0.764rem 1.688rem;
  &:disabled {
    opacity: 0.5 !important;
  }
}

.btn-md {
  padding: 0.625rem 1.5rem;
}

.btn-sm {
  font-weight: 600;
  font-size: 0.625rem;
  line-height: 0.75rem;
  letter-spacing: 0.25em;
  padding: 0.5rem 1.875rem;
}

.btn-secondary-light,
.secondary-popup-button {
  color: $black !important;
  border: 1px solid $black;
  background-color: $white !important;
  font-weight: 600;
  font-size: 0.625rem;
  line-height: 0.75rem;
  letter-spacing: 0.25em;
  padding: 0.5rem 1.875rem;
  text-transform: uppercase;
  border-color: $black !important;
  &:disabled {
    color: $black !important;
    background-color: $white !important;
  }
}

.btn-abroad-blue {
  color: $black !important;
  border: 1px solid $abroad-blue;
  background-color: $white !important;
  font-weight: 600;
  font-size: 0.625rem;
  line-height: 0.75rem;
  letter-spacing: 0.25em;
  padding: 0.5rem 1.875rem;
  text-transform: uppercase;
  border-color: $abroad-blue !important;
  &:disabled {
    color: $black !important;
    background-color: $white !important;
  }
}
.btn-session {
  font-size: 0.75rem;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.15em;
  color: white;
  text-align: center;
  padding: 0.75rem 1.5rem;
  opacity: 1;
  border-color: map-get($saffron, '300');
  background: map-get($saffron, '300');
  border-radius: 0.313rem;
  width: fit-content;
  &:hover,
  &:active,
  &:focus-visible {
    background-color: map-get($saffron, '600') !important;
    border-color: map-get($saffron, '600');
    color: $white !important;
  }
}

.btn-saffron-square {
  line-height: normal !important;
  @extend .s9;
  background-color: map-get($saffron, '300');
  color: $white;
  text-align: center;
  border-radius: 3px;
  padding: 0.625rem 1.625rem;
  &:hover,
  &:active,
  &:focus-visible {
    background-color: map-get($saffron, '700') !important;
    color: $white !important;
  }
  &:disabled {
    background-color: map-get($saffron, '300') !important;
    color: $white !important;
    opacity: 0.5;
  }
}

.btn-outline-dark {
  line-height: normal !important;
  @extend .s9;
  background-color: transparent;
  color: $white;
  text-align: center;
  border-radius: 100px;
  padding: 1rem 0;
  border: 1px solid $white;
  &:hover,
  &:active,
  &:focus-visible {
    background-color: map-get($saffron, '300') !important;
    border: 1px solid $black;
    color: $black !important;
  }
  &:disabled {
    opacity: 1;
    background-color: transparent !important;
    border: 1px solid $white !important;
    color: $white !important;
  }
}
.custom-previous-next {
  .previous-btn {
    line-height: normal;
    @extend .s9;
    background-color: transparent;
    color: $white;
    text-align: center;
    padding: 1rem 0;
    border: 1px solid $white;
  }
  .next-btn {
    line-height: normal;
    @extend .s9;
    background-color: map-get($saffron, '300');
    color: $black;
    text-align: center;
    padding: 1rem 1.5rem;
    border: 1px solid map-get($saffron, '300');
    &:disabled {
      background-color: map-get($saffron, '300') !important;
      color: $black !important;
      opacity: 0.5 !important;
    }
  }
}
.btn-custom {
  line-height: normal !important;
  @extend .s9;
  text-align: center;
  border-radius: 100px;
  padding: 1rem 0;
}

.btn-md {
  padding: 0.75rem 2rem !important;
  @extend .s10c;
}

.btn-abroad-blue {
  // btn-variant($bgColor, $focusBGColor, $disabledBGColor, $color)
  @include btn-variant($abroad-blue, $abroad-blue, $abroad-blue, $white);
  border-radius: 0.313rem;
  @extend .s6b;
  padding: 0.75rem 1.5rem;
}
