.intention {
  .form-control:focus {
    outline: none;
    box-shadow: none;
  }

  .action-icons {
    font-size: 1.5rem;
  }
  .audio-title {
    caret-color: $white;
    &:focus {
      color: $white !important;
    }
    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
      @extend .s7;
    }
  }
}
.intention-input {
  .icon-growth-settings {
    height: 23px;
  }
}
.archive-intention {
  border-radius: 5px;
  border: 2px solid $white;
  background: $abroad-blue;
  opacity: 1 !important;
  // margin-top: 1rem;
  & .tooltip-arrow {
    display: none;
  }
  & .tooltip-inner {
    background: transparent;
    text-align: left !important;
    padding: 1.125rem 1.625rem;
  }
  & .archive-link {
    @extend .s11;
    color: $white;
  }
}
.delete-link {
  color: map-get($red, '800');
}
.intention-card:hover {
  .intention-text,
  .intention-title {
    font-weight: 700 !important;
  }
}
.intention-form-group {
  & textarea.form-control {
    resize: none;
  }
}
