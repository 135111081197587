// css for react-transition-group
.left-to-right-enter {
  opacity: 0;
  transform: translateX(-15%);
}
.left-to-right-enter-active {
  opacity: 1;
  transform: translateX(0%);
}
.left-to-right-exit {
  opacity: 1;
  transform: translateX(0%);
}
.left-to-right-exit-active {
  opacity: 0;
  transform: translateX(-15%);
}
.left-to-right-enter-active,
.left-to-right-exit-active {
  transition: opacity 300ms, transform 300ms;
}

.right-to-left-enter {
  opacity: 0;
  transform: translateX(15%);
}
.right-to-left-enter-active {
  opacity: 1;
  transform: translateX(0%);
}
.right-to-left-exit {
  opacity: 1;
  transform: translateX(0%);
}
.right-to-left-exit-active {
  opacity: 0;
  transform: translateX(15%);
}
.right-to-left-enter-active,
.right-to-left-exit-active {
  transition: opacity 300ms, transform 300ms;
}
