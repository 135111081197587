.fevorite {
  .shimmer-badge {
    height: 2rem !important;
    margin-right: 0.5rem !important;
    margin-bottom: 0 !important;
  }
}
.custom-ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 97%;
}
.flex-basis-100 {
  flex-basis: 100%;
}
