.bar {
  user-select: none;
  width: 100%;
  display: flex;
  align-items: center;

  .bar-progress {
    flex: 1;
    border-radius: 5px;
    margin: 0 16px;
    height: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #6f6f6f;

    .bar-progress-knob {
      position: relative;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      left: -8px;
    }
    &:hover {
      .bar-progress-knob {
        background-color: white;
        border: 1.5px solid white;
      }
    }
    &.disabled {
      cursor: none !important;
      background: linear-gradient(
        to right,
        #f6f6f6 8%,
        #f0f0f0 18%,
        #f6f6f6 33%
      );
      animation: shimmer 2.2s linear infinite forwards;
    }
  }
}

.vol-bar-root {
  display: inline-flex;
  width: 10rem;
}

.bar-vol {
  user-select: none;
  width: 100%;
  display: flex;
  align-items: center;

  .bar-volume {
    flex: 1;
    border-radius: 5px;
    margin-left: 16px;
    height: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #6f6f6f;

    .bar-volume-knob {
      position: relative;
      height: 16px;
      width: 16px;
      border-radius: 50%;
      left: -8px;
    }
    &:hover {
      .bar-volume-knob {
        background-color: white;
        border: 1.5px solid white;
      }
    }
  }
}

.bar-time {
  margin: 0 16px;
}

.mini-playbar {
  flex: 1;
  min-width: 0;
  .title-shimmer {
    min-width: 200px;
  }
  .title-link {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// media playbar animations start
@include media-breakpoint-up(lg) {
  .progress-bar-enter {
    opacity: 1;
    transform: scale(0.9);
  }
  .progress-bar-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .progress-bar-exit {
    opacity: 0;
  }
  .progress-bar-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }

  .play-bar-enter {
    bottom: -6rem;
    opacity: 0;
  }
  .play-bar-enter-active {
    opacity: 1;
    bottom: 0;
    transition: opacity 500ms, bottom 500ms;
  }
  .play-bar-enter-done {
    opacity: 1;
    bottom: 0;
  }
  .play-bar-exit {
    bottom: 0;
    opacity: 1;
  }
  .play-bar-exit-active {
    opacity: 0;
    bottom: -6rem;
    transition: opacity 500ms, bottom 500ms;
  }
}

@include media-breakpoint-down(lg) {
  .play-bar-enter {
    bottom: -4rem;
    opacity: 0;
  }
  .play-bar-enter-active {
    opacity: 1;
    bottom: 0;
    transition: opacity 500ms, bottom 500ms;
  }
  .play-bar-enter-done {
    opacity: 1;
    bottom: 0;
  }
  .play-bar-exit {
    bottom: 0;
    opacity: 1;
  }
  .play-bar-exit-active {
    opacity: 0;
    bottom: -4rem;
    transition: opacity 500ms, bottom 500ms;
  }
  .layout-with-play-bar {
    padding-bottom: 4rem;
    transition: padding-bottom 500ms ease-out;
  }

  .sm-audio-control-enter {
    height: 0;
    transform: translateY(8.313rem);
  }
  .sm-audio-control-enter-active {
    height: 8.313rem;
    transform: translateY(0);
    transition: all 500ms;
  }

  .sm-audio-control-exit {
    height: 8.313rem;
    transform: translateY(0);
  }
  .sm-audio-control-exit-active {
    height: 0;
    transform: translateY(8.313rem);
    transition: all 500ms;
  }
}
// media playbar animations over

.playbar-top-border {
  border-top: 1px solid #fff;
}

.rotate-90 {
  transform: rotate(90deg);
  /* Legacy vendor prefixes*/

  /* Safari */
  -webkit-transform: rotate(90deg);
  /* Firefox */
  -moz-transform: rotate(90deg);
  /* IE */
  -ms-transform: rotate(90deg);
  /* Opera */
  -o-transform: rotate(90deg);
  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

$mediabar-border-radius: 5px;
$mediabar-base-height: 2px;
.mediabar-wrapper-new {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  height: $mediabar-base-height;
  background-color: rgba(217, 217, 217, 0.5);
  border-radius: $mediabar-border-radius;
  margin: 0 16px;
  cursor: pointer;

  &:hover {
    .custom-bar-progress-knob {
      background-color: white;
      border: 1.5px solid white;
    }
  }

  &.disabled {
    cursor: none !important;
    background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
    animation: shimmer 2.2s linear infinite forwards;
  }
}

.mediabar-buffer-new {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  opacity: 0.3;
  background-color: #fff;
  border-radius: $mediabar-border-radius;
}

.mediabar-progress-new {
  position: absolute;
  top: -1px;
  left: 0;
  z-index: 3;
  height: calc($mediabar-base-height * 2);
  border-radius: $mediabar-border-radius;
}

.custom-bar-progress-knob {
  position: absolute;
  top: -7px;
  z-index: 10;
  height: 16px;
  width: 16px;
  border-radius: 50%;
}
.player-journal-icon:hover {
  path {
    stroke: map-get($saffron, '700');
  }
}
.player-journal-icon.active {
  .journel-line {
    stroke: $black;
  }
}
.information-icon {
  circle {
    stroke: $white;
  }
  path {
    fill: $white;
  }
}
.information-icon:hover {
  circle {
    stroke: map-get($saffron, '700');
  }
  path {
    fill: map-get($saffron, '700');
  }
}
.information-icon.active {
  circle {
    fill: map-get($saffron, '700');
    stroke: map-get($saffron, '700');
  }
  path {
    fill: $white;
  }
}
.backword-icon-wrapper:hover {
  .icon-backward::before {
    color: map-get($saffron, '700');
  }
}
.forward-icon-wrapper:hover {
  .icon-forward::before {
    color: map-get($saffron, '700');
  }
}
