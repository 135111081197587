.pending-assessment-tab {
  @extend .s10c;
  border-radius: 0.313rem;
  border: 2px solid $abroad-blue;
  color: $abroad-blue;
  padding: 1rem;
  margin-right: 1.938rem;
  margin-bottom: 1.5rem;
  &.active,
  &:hover {
    color: $white;
    background-color: $abroad-blue;
  }
  &.active {
    outline: none !important;
  }
  &:disabled,
  &.disabled {
    opacity: 0.5 !important;
    pointer-events: none;
  }
}
.preview-email {
  padding: 1.5rem;
  @extend .s6;
  border: 2px solid $black;
  background: map-get($gray, '100');
  color: $black;
}

.request-inputs {
  .form-label {
    margin: 1rem 0rem 0.5rem 0rem;
    @extend .s10c;
  }
  .form-control {
    box-shadow: none !important;
    border-radius: 0.313rem;
    background-color: $white;
    border: 1px solid $black;
    @extend .s7;
    padding: 0.4rem 0.75rem;
    &.is-invalid {
      background-image: none !important;
      padding-right: 0.75rem;
      border: 1px solid $invalid-color;
    }
    .was-validated &:valid,
    &.is-valid {
      border: 1px solid $valid-color;
      background-image: none !important;
    }
    &:disabled {
      background-color: var(--bs-secondary-bg);
    }
    & input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
      border: 1px solid $black !important;
      background: $white !important;
      background-color: $white !important;
      color: $black !important;
    }
  }
}
.report-score {
  font-family: Open Sans;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.125rem;
  letter-spacing: -0.025rem;
}
.report-tooltip {
  opacity: 1 !important;
  .tooltip-arrow {
    display: none !important;
  }
  .tooltip-inner {
    border-radius: 10px !important;
    border: 2px solid $black !important;
    background: $white !important;
    padding: 1.875rem 2.25rem !important;
    max-width: 23rem !important;
    width: 100%;
    opacity: 1 !important;
  }
}
@include media-breakpoint-down(md) {
  .pending-assessment-tab {
    margin-right: 0rem;
  }
}
@include media-breakpoint-down(md) {
  .report-tooltip {
    max-width: calc(100% - 10px) !important;
    .tooltip-inner {
      max-width: 100% !important;
    }
  }
}
