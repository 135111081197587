@font-face {
  font-family: 'icon';
  src: url('../assets/fonts/icon.eot?mfk3bh');
  src: url('../assets/fonts/icon.eot?mfk3bh#iefix') format('embedded-opentype'),
    url('../assets/fonts/icon.ttf?mfk3bh') format('truetype'),
    url('../assets/fonts/icon.woff?mfk3bh') format('woff'),
    url('../assets/fonts/icon.svg?mfk3bh#icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-briefcase:before {
  content: '\e900';
  color: rgba(255, 255, 255, 0.6);
}
.icon-home:before {
  content: '\e901';
  color: rgba(255, 255, 255, 0.6);
}
.icon-assessments:before {
  content: '\e902';
  color: rgba(255, 255, 255, 0.6);
}
.icon-coaching:before {
  content: '\e903';
  color: rgba(255, 255, 255, 0.6);
}
.icon-learning:before {
  content: '\e904';
  color: rgba(255, 255, 255, 0.6);
}
.icon-quests:before {
  content: '\e905';
  color: rgba(255, 255, 255, 0.6);
}
.icon-journal:before {
  content: '\e906';
}
.icon-notification:before {
  content: '\e907';
}
.icon-user:before {
  content: '\e908';
}
.icon-backward:before {
  content: '\e909';
}
.icon-forward:before {
  content: '\e90a';
}
.icon-bookmark:before {
  content: '\e90b';
}
.icon-bookmark-gold:before {
  content: '\e90c';
  color: #bf9000;
}
.icon-check-gold:before {
  content: '\e90d';
  color: #bf9000;
}
.icon-cross:before {
  content: '\e90e';
}
.icon-download:before {
  content: '\e90f';
}
.icon-download-gold .path1:before {
  content: '\e910';
  color: rgb(191, 144, 0);
}
.icon-download-gold .path2:before {
  content: '\e911';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-eye:before {
  content: '\e912';
}
.icon-extra-settings:before {
  content: '\e913';
}
.icon-hamburger:before {
  content: '\e914';
}
.icon-info:before {
  content: '\e915';
}
.icon-left-arrow:before {
  content: '\e916';
}
.icon-right-arrow:before {
  content: '\e917';
}
.icon-menu-left-arrow:before {
  content: '\e918';
}
.icon-microphone:before {
  content: '\e919';
}
.icon-next:before {
  content: '\e91a';
}
.icon-previous:before {
  content: '\e91b';
}
.icon-pause:before {
  content: '\e91c';
}
.icon-volume:before {
  content: '\e91d';
}
.icon-padlock:before {
  content: '\e91e';
}
.icon-pencil:before {
  content: '\e91f';
}
.icon-play:before {
  content: '\e920';
}
.icon-play-media .path1:before {
  content: '\e921';
  color: #fff;
}
.icon-play-media .path2:before {
  content: '\e922';
  margin-left: -1em;
  color: #000;
}
.icon-play-media.paused .path2:before {
  content: '\e91c';
  margin-left: -1em;
  color: #000;
}
.icon-play-media-gold .path1:before {
  content: '\e921';
  color: #bf9000;
}
.icon-play-media-gold .path2:before {
  content: '\e922';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-plus:before {
  content: '\e923';
}
.shimmer-text-height .shimmer-text-line {
  height: 1rem;
}
