@mixin swiper-buttons($top) {
  .swiper-button-prev {
    width: 1rem !important;
    height: 1.438rem !important;
    top: $top !important;
    &::after {
      content: none;
      display: none;
    }
  }

  .swiper-button-next {
    width: 1rem !important;
    height: 1.438rem !important;
    top: $top !important;
    &::after {
      content: none;
      display: none;
    }
  }
}

@mixin background-opacity($color, $opacity: 1) {
  background: $color !important;
  background: rgba($color, $opacity) !important;
}

@mixin transition-all {
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

@mixin make-grid-columns(
  $columns: $grid-columns,
  $gutter: $grid-gutter-width,
  $breakpoints: $grid-breakpoints
) {
  // Common properties for all breakpoints
  %grid-column {
    position: relative;
    width: 100%;
    padding-right: $gutter * 0.5;
    padding-left: $gutter * 0.5;
  }

  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @if $columns > 0 {
      // Allow columns to stretch full width below their breakpoints
      @for $i from 1 through $columns {
        .custom-col#{$infix}-#{$i} {
          @extend %grid-column;
        }
      }
    }

    .custom-col#{$infix},
    .custom-col#{$infix}-auto {
      @extend %grid-column;
    }

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      // Provide basic `.col-{bp}` classes for equal-width flexbox columns
      .custom-col#{$infix} {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }

      @if $grid-row-columns > 0 {
        @for $i from 1 through $grid-row-columns {
          .custom-row-cols#{$infix}-#{$i} {
            @include row-cols($i);
          }
        }
      }

      .custom-col#{$infix}-auto {
        @include make-col-auto();
      }

      @if $columns > 0 {
        @for $i from 1 through $columns {
          .custom-col#{$infix}-#{$i} {
            @include make-col($i, $columns);
          }
        }
      }

      .custom-order#{$infix}-first {
        order: -1;
      }

      .custom-order#{$infix}-last {
        order: $columns + 1;
      }

      @for $i from 0 through $columns {
        .custom-order#{$infix}-#{$i} {
          order: $i;
        }
      }

      @if $columns > 0 {
        // `$columns - 1` because offsetting by the width of an entire row isn't possible
        @for $i from 0 through ($columns - 1) {
          @if not($infix == '' and $i == 0) {
            // Avoid emitting useless .offset-0
            .custom-offset#{$infix}-#{$i} {
              @include make-col-offset($i, $columns);
            }
          }
        }
      }
    }
  }
}

@mixin background-opacity($color, $opacity: 1) {
  background: $color !important;
  background: rgba($color, $opacity) !important;
}

@mixin text-decoration-none {
  & {
    text-decoration: none !important;
    &:hover {
      text-decoration: none !important;
    }
  }
}

@mixin tabs-horizontal-scroll {
  overflow: auto;
  white-space: nowrap;
  overflow-x: scroll;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
@mixin gray-scrollbar {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    background-color: transparent;
    width: 8px;
  }

  /* scrollbar itself */
  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
  }

  /* set button(top and bottom of the scrollbar) */
  ::-webkit-scrollbar-button {
    display: none;
  }
}

@mixin customize-scrollbar {
  ::-webkit-scrollbar {
    width: 8px;
    cursor: pointer;
  }

  ::-webkit-scrollbar-track {
    background-color: #b3b3b3;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #000;
  }
}

@mixin btn-variant($bgColor, $focusBGColor, $disabledBGColor, $color) {
  line-height: normal !important;
  @extend .s9;
  background-color: $bgColor;
  color: $color;
  text-align: center;
  border-radius: 100px;
  padding: 1rem 1.5rem;
  &.text-white {
    color: #fff !important;
    &:hover {
      color: #fff !important;
    }
  }
  &:hover,
  &:active,
  &:focus-visible {
    background-color: $focusBGColor !important;
    color: $color !important;
    &.text-white {
      color: #fff !important;
      &:hover {
        color: #fff !important;
      }
    }
  }
  &:disabled {
    background-color: $disabledBGColor !important;
    color: $color !important;
    opacity: 0.5;
    &.text-white {
      color: #fff !important;
      &:hover {
        color: #fff !important;
      }
    }
  }
  &.border-radius-normal {
    border-radius: 5px;
  }
}
@mixin range-slider-thumb($bgColor) {
  background-color: $bgColor;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}
