.my-info {
  label {
    @extend .s10c;
    color: $black;
    margin-bottom: 0.5rem !important;
  }
  ::placeholder {
    @extend .s7;
    color: $black !important;
    opacity: 1 !important;
  }
  .form-control {
    @extend .s7;
    background-image: none;
    padding: 0.438rem 0.875rem;
    border-radius: 5px;
    border: 1px solid $black;
    .was-validated &:invalid,
    &.is-invalid {
      border-color: $invalid-color !important;
    }
    .was-validated &:valid,
    &.is-valid {
      border-color: $valid-color !important;
    }
    &:disabled {
      background-color: map-get($gray, '100') !important;
    }
  }
  .input-field-group {
    margin-bottom: 2rem;
  }
  select:disabled {
    background-color: map-get($gray, '100') !important;
  }
  select {
    background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.28.214a.72.72 0 010 1.036l-4 3.904a.763.763 0 01-1.06 0l-4-3.904a.72.72 0 010-1.036.763.763 0 011.06 0l3.47 3.387L8.22.214a.763.763 0 011.06 0z" fill="%23000"/></svg>')
      no-repeat !important;
    background-position: calc(100% - 0.813rem) 50% !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;
  }
  .info-text {
    margin-top: 1.25rem;
  }
}
