@import './variables.scss';
//xl:
@media (min-width: 1200px) {
  .compare-thumbnail-responsive {
    .shimmer-thumbnail {
      height: 43.75rem !important;
    }
  }

  .compared-thumbnail-responsive {
    .shimmer-thumbnail {
      height: 23.75rem !important;
    }
  }

  .category-thumbnail-responsive {
    .shimmer-thumbnail {
      height: 23.75rem !important;
    }
  }

  .SL-request-thumbnail {
    .shimmer-thumbnail {
      height: 38.75rem !important;
    }
  }

  .center {
    display: flex !important;
    justify-content: center !important;

    .shimmer-thumbnail {
      width: 16.75rem !important;
    }
  }

  .bg-color {
    background-color: #e0e0e0;
  }
  .responsive-thumbnail {
    .shimmer-thumbnail {
      height: 180px !important;
    }
  }
  .responsive-sub-thumbnail {
    .shimmer-thumbnail {
      height: 70px !important;
    }
  }
  .responsive-entries-thumbnail {
    .shimmer-thumbnail {
      height: 125px !important;
    }
  }
  .assessment-content-wrapper {
    width: 100%;
  }
  .slider-custom-thumbnail {
    .shimmer-thumbnail {
      width: 200px !important;
      height: 200px !important;
    }
  }
}

//lg:
@media (max-width: 1200px) {
  .compare-thumbnail-responsive {
    .shimmer-thumbnail {
      height: 43.75rem !important;
    }
  }

  .compared-thumbnail-responsive {
    .shimmer-thumbnail {
      height: 23.75rem !important;
    }
  }

  .category-thumbnail-responsive {
    .shimmer-thumbnail {
      height: 23.75rem !important;
    }
  }

  .SL-request-thumbnail {
    .shimmer-thumbnail {
      height: 38.75rem !important;
    }
  }

  .center {
    display: flex !important;
    justify-content: center !important;

    .shimmer-thumbnail {
      width: 12.5rem !important;
    }
  }

  .bg-color {
    background-color: #e0e0e0;
  }

  .responsive-thumbnail {
    .shimmer-thumbnail {
      height: 275px !important;
    }
  }
  .responsive-sub-thumbnail {
    .shimmer-thumbnail {
      height: 100px !important;
    }
  }
  .responsive-entries-thumbnail {
    .shimmer-thumbnail {
      height: 130px !important;
    }
  }
  .assessment-content-wrapper {
    width: 100%;
  }
  .slider-custom-thumbnail {
    .shimmer-thumbnail {
      width: 250px !important;
      height: 250px !important;
    }
  }
}

//md:
@media (max-width: 992px) {
  .compare-thumbnail-responsive {
    .shimmer-thumbnail {
      height: 6.25rem !important;
    }
  }

  .compared-thumbnail-responsive {
    .shimmer-thumbnail {
      height: 28.12rem !important;
    }
  }

  .category-thumbnail-responsive {
    .shimmer-thumbnail {
      height: 23.75rem !important;
    }
  }

  .SL-request-thumbnail {
    .shimmer-thumbnail {
      height: 22.75rem !important;
    }
  }

  .center {
    display: flex !important;
    justify-content: center !important;

    .shimmer-thumbnail {
      width: 18.75rem !important;
    }
  }

  .bg-color {
    background-color: #e0e0e0;
  }
  .responsive-thumbnail {
    .shimmer-thumbnail {
      height: 225px !important;
    }
  }
  .responsive-sub-thumbnail {
    .shimmer-thumbnail {
      height: 80px !important;
    }
  }
  .responsive-entries-thumbnail {
    .shimmer-thumbnail {
      height: 110px !important;
    }
  }
  .assessment-content-wrapper {
    width: 30%;
  }
  .slider-custom-thumbnail {
    .shimmer-thumbnail {
      width: 200px !important;
      height: 200px !important;
    }
  }
}

//sm:
@media (max-width: 768px) {
  .compare-thumbnail-responsive {
    .shimmer-thumbnail {
      height: 6.25rem !important;
    }
  }

  .compared-thumbnail-responsive {
    .shimmer-thumbnail {
      height: 28.12rem !important;
    }
  }

  .category-thumbnail-responsive {
    .shimmer-thumbnail {
      height: 26.25rem !important;
    }
  }

  .SL-request-thumbnail {
    .shimmer-thumbnail {
      height: 22.75rem !important;
    }
  }

  .LA-result-thumbnail {
    .shimmer-thumbnail {
      height: 17.75rem !important;
    }
  }

  .center {
    display: flex !important;
    justify-content: center !important;

    .shimmer-thumbnail {
      width: 18.75rem !important;
    }
  }

  .bg-color {
    background-color: #e0e0e0;
  }

  .responsive-thumbnail {
    .shimmer-thumbnail {
      height: 250px !important;
    }
  }
  .responsive-sub-thumbnail {
    .shimmer-thumbnail {
      height: 60px !important;
    }
  }
  .responsive-entries-thumbnail {
    .shimmer-thumbnail {
      height: 150px !important;
    }
  }
  .assessment-content-wrapper {
    width: 50%;
  }
  .slider-custom-thumbnail {
    .shimmer-thumbnail {
      width: 175px !important;
      height: 175px !important;
    }
  }
}

// xs:
@media (max-width: 576px) {
  .compare-thumbnail-responsive {
    .shimmer-thumbnail {
      height: 6.25rem !important;
    }
  }

  .compared-thumbnail-responsive {
    .shimmer-thumbnail {
      height: 31.25rem !important;
    }
  }

  .category-thumbnail-responsive {
    .shimmer-thumbnail {
      height: 26.25rem !important;
    }
  }

  .SL-request-thumbnail {
    .shimmer-thumbnail {
      height: 22.75rem !important;
    }
  }

  .LA-result-thumbnail {
    .shimmer-thumbnail {
      height: 17.75rem !important;
    }
  }

  .center {
    display: flex !important;
    justify-content: center !important;

    .shimmer-thumbnail {
      width: 18.75rem !important;
    }
  }

  .bg-color {
    background-color: #706f6f;
  }

  .bg-color {
    background-color: #e0e0e0;
  }

  .responsive-thumbnail {
    .shimmer-thumbnail {
      height: 350px !important;
    }
  }
  .responsive-sub-thumbnail {
    .shimmer-thumbnail {
      height: 50px !important;
    }
  }
  .responsive-entries-thumbnail {
    .shimmer-thumbnail {
      height: 125px !important;
    }
  }
  .slider-custom-thumbnail {
    .shimmer-thumbnail {
      width: 200px !important;
      height: 200px !important;
    }
  }
}
.custom-thumbnail-bg {
  background-color: #efefef;
}
