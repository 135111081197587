$grid-columns: 12;
$grid-gutter-width: 1.5rem;
// @import '~@abroad/components/src/styles.module.scss';
@import '~bootstrap/scss/bootstrap.scss';

@media (max-width: 1600px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 1601px) and (max-width: 1919px) {
  html {
    font-size: 18px;
  }
}

@media (min-width: 1920px) and (max-width: 2239px) {
  html {
    font-size: 20px;
  }
}

@media screen and (min-width: 2240px) and (max-width: 3300px) {
  html {
    font-size: 22px;
  }
}

@media screen and (min-width: 3301px) and (max-width: 3839px) {
  html {
    font-size: 24px;
  }
}

@media screen and (min-width: 3840px) {
  html {
    font-size: 28px;
  }
}

// utilities
@import './variables.scss';
@import './mixins.scss';
@import './function.scss';
@import './common.scss';
@import './spacing.scss';
@import './button.scss';
@import './react-transition.scss';

// components
@import './react-cookie.scss';
@import './media-module.scss';
@import './header.scss';
@import './layout.scss';
@import './tabs.scss';
@import './sidebar.scss';
@import './table.scss';
@import './growth.scss';
@import './mediaPlayer.scss';
@import './notification.scss';
@import './coach-circles.scss';
@import './shimmerEffect.scss';

// pages
@import './onboarding.scss';
@import './login.scss';
@import './signup.scss';
@import './home.scss';
@import './coach-recommends.scss';
@import './leader-assessments.scss';
@import './simple360.scss';
@import './assessment-result.scss';
@import './comparision-report.scss';
@import './miscellaneous-pages.scss';
@import './download-app.scss';
@import './favorite.scss';
@import './plan-payments.scss';
@import './profile.scss';
@import './user-payment.scss';
@import './Quests.scss';

body {
  --bs-body-color: $black;
}

.body-content {
  @include make-grid-columns(10);
}
.continue-abroad-btn {
  margin-top: 8rem;
}
.is-horizontal-label {
  margin-right: 0.75rem;
}
.is-horizontal-control {
  margin-left: 0.75rem;
}
.vol-bar-root {
  .icon-volume {
    path {
      stroke: $white;
    }
    &:hover {
      path {
        stroke: map-get($saffron, '700');
      }
    }
  }
}
.pdf-title {
  flex-basis: 80%;
}
@include media-breakpoint-down(lg) {
  .continue-abroad-btn {
    margin-top: 2rem;
  }
  .is-horizontal-label {
    margin-bottom: 0.75rem;
  }
  .is-horizontal-control {
    margin-left: 0;
  }
}

.coach-image {
  aspect-ratio: 1;
  object-fit: cover;
}

@media print {
  .print-header {
    display: none !important;
  }
  .score-height {
    height: 22.4rem !important;
  }
}
