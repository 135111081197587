.user-payment {
  max-width: 424px;
  margin: 0 auto;
  & .title {
    margin-bottom: 10px;
  }
  .field {
    margin-bottom: 0 !important;
  }
  .promo {
    .form-control {
      border: 1px solid darkgrey !important;
      border-right: none !important;
      background: $white;
      font-size: 10pt;
      color: $black;
    }
    .promo-text {
      bottom: -1.8rem;
    }
  }
  .promo-group {
    margin-bottom: 7px;
  }
  label {
    font-family: 'Montserrat' !important;
    font-weight: 600 !important;
    color: $black !important;
    font-size: 0.625rem !important;
    line-height: 0.75rem !important;
    letter-spacing: 0.25em !important;
  }
  & .make-payment {
    height: 40px;
    & .btn-spinner {
      width: 0.875rem; //14px
      height: 0.875rem; //14px
    }
  }
  .form-control {
    background: $white !important;
    border: 1px solid $black !important;
    border-radius: 3px;
    font-size: 12pt;
    color: $black;
    .was-validated &:invalid,
    &.is-invalid {
      border-color: $invalid-color !important;
    }
    .was-validated &:valid,
    &.is-valid {
      border-color: $valid-color !important;
    }
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: map-get($dark, '900-5') !important;
      opacity: 0.5 !important; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: map-get($dark, '900-5') !important;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: map-get($dark, '900-5') !important;
    }
  }

  .summary-title {
    padding: 5px 10px;
    border-bottom: 1px solid $black;
  }
  .promo-code-wrp {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px;
  }
  .promo-details {
    padding: 0 10px;
    margin-left: 0 !important;
  }
  .promo-code-text {
    margin: 0 10px;
    width: 50%;
  }
  .promo-code-label div {
    font-size: 14px;
    font-weight: 600;
  }
  .payment-details-form-wrp {
    padding: 20px 0;
  }
  .expiration-text {
    width: 40%;
    /* display: inline-block; */
  }
  #card-exp {
    display: none;
  }
  .padding-handle {
    padding: 0 10%;
  }
  .question-icon {
    height: 30px;
    width: 30px;
    margin-left: 3px;
  }
  .credit-card-wrp {
    position: relative;
  }

  .payment-form-label {
    font-size: 14px;
    margin: 0;
    font-weight: 600;
    flex-basis: 115px;
    color: rgba(26, 26, 26, 0.7);
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }

  .custom-templates ul li {
    font-size: 11px;
    font-weight: 600;
  }
  .cart {
    border: 1px solid #333;
  }

  .cbody {
    background-color: $white;
  }

  .cbody h4 {
    margin-left: 4px;
  }

  .cbody p {
    margin-left: 4px;
  }

  .cfooter {
    background: #808080;
    color: $white;
    padding: 0.3em 1.5em;
  }

  .cfooter p {
    margin: 5px;
    font-weight: 800;
  }

  .cfooter > p > span {
    float: right;
  }
  .apply-button {
    margin: 0;
    background-color: transparent;
    text-align: center;
    border-radius: 0;
  }
  .btn.btn-primary {
    color: $white;
    background: $black;
    border: 2px solid #333333;
  }
  .apply-button button {
    font-size: 12px !important;
    padding: 10px;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.1s ease;
  }
  .btn.btn-primary:hover,
  .btn.btn-primary:active,
  .btn.btn-primary:focus {
    background: #fff;
    color: #333333;
    border-color: #333333;
    box-shadow: none;
  }
  .btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }

  .btn.btn-primary:hover i.arrow {
    border-color: #333333;
  }

  .btn.btn-primary.btn-clear {
    background: none;
    color: #333333;
  }

  .btn.btn-primary.btn-clear:hover {
    background: #333333;
    color: $white;
  }

  .btn.btn-primary.btn-header {
    padding: 9px;
    margin: 5px 20px;
    border-color: $white;
    width: 180px;
    font-weight: 300;
  }

  .btn.btn-primary.btn-header:hover {
    background: $white;
    color: #333333;
    font-weight: 300;
  }

  .btn-lg,
  .btn-group-lg > .btn {
    -webkit-border-radius: 300px;
    border-radius: 5px;
    background-clip: padding-box;
    font-size: 14px !important;
    letter-spacing: 3px;
    font-weight: 300;
  }

  .btn.btn-primary.btn-clear.disabled:hover {
    background: none;
    color: #333333;
  }

  .payment-details-form-wrp {
    padding: 20px 0;
  }
  .checkout-form {
    width: 100%;
  }
  .form-label {
    font-size: 14px;
    margin: 0;
    font-weight: 600;
    flex-basis: 115px;
  }

  label {
    display: inline-block;
    margin-bottom: 5px;
    font-weight: 300;
    font-size: 16px;
    color: rgba(26, 26, 26, 0.7);
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
  .custom-templates .form-control {
    margin: 0;
    border-radius: 5px;
    border: 1px solid darkgray;
  }

  .expiration-text {
    width: 40%;
  }
  .cvv-text {
    width: 60%;
    display: inline-block;
  }
  @media (max-width: 767px) {
    .padding-handle {
      padding: 0 15px;
    }
  }

  @media (max-width: 600px) {
    .promo-code-wrp {
      display: block;
    }
    .promo-code-text {
      margin: 10px 0;
      width: 100%;
    }
    .apply-button {
      text-align: left;
    }
  }

  @media only screen and (max-width: 496px) {
    .assessment-button {
      width: 100% !important;
    }
    .cfooter {
      padding: 0;
    }
  }
}
.mb-2-rem {
  margin-bottom: 2rem !important;
}
.summary-separator {
  border-bottom: 1px solid #000000;
  margin: 0.75rem 0;
}
.apply-promo-button {
  font-size: 0.625rem !important;
  line-height: 0.75rem !important;
  letter-spacing: 0.25em !important;
  font-weight: 600;
  background-color: $black;
  color: $white;
  text-align: center;
  border-radius: 5px;
  padding: 8px 30px;
  width: 8.75rem;
  height: 32px;
  &:hover:enabled,
  &:active:enabled,
  &:focus-visible:enabled {
    background-color: #282828;
    color: $white;
    box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.3);
  }
  &:disabled {
    background-color: #282828;
    border-color: #282828;
    color: $white;
  }
}
.apply-promo-input {
  font-family: Open Sans;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 1.25rem !important;
  background: $white;
  border: 1px solid $black;
  border-radius: 5px !important;
  padding: 6px 13px;
  color: $black !important;
  height: 32px;
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: map-get($dark, '900-5') !important;
    opacity: 0.5 !important; /* Firefox */
    line-height: 1.25rem !important;
    color: $black !important;
    letter-spacing: normal !important;
    line-height: 20px;
    font-weight: 500;
    font-family: Open Sans;
    font-size: 12px;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: map-get($dark, '900-5') !important;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: map-get($dark, '900-5') !important;
  }
}
.summary-label {
  font-family: Montserrat !important;
  font-size: 0.625rem !important;
  line-height: 0.75rem !important;
  letter-spacing: 0.25em !important;
  text-transform: uppercase !important;
}
.summary-text {
  font-family: Open Sans !important;
  font-size: 0.875rem !important;
  line-height: 1.5rem !important;
  letter-spacing: -0.02em !important;
}
.forward-slash-label {
  width: 6px;
  height: 12px;
  margin-bottom: 0px !important;
}
@include media-breakpoint-up(lg) {
  .payment-details {
    margin-top: 2rem !important;
  }
}
//stripe card style
.card-labels {
  font-family: Montserrat;
  font-size: 0.625rem; //10px
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.156rem; //2.5px
}

.payment-details .StripeElement {
  background: $white !important;
  border: 1px solid $black !important;
  border-radius: 3px !important;
  font-size: 12pt;
  color: $black;
  // height: 32px;
  & ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: map-get($dark, '900-5') !important;
    opacity: 0.5 !important; /* Firefox */
  }

  & :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: map-get($dark, '900-5') !important;
    opacity: 0.5 !important;
  }

  & ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: map-get($dark, '900-5') !important;
    opacity: 0.5 !important;
  }
  &.StripeElement--invalid {
    border-color: $invalid-color !important;
  }
  &.StripeElement--invalid.StripeElement--focus {
    box-shadow: 0 0 0 0.2rem rgba(188, 76, 106, 0.25);
  }
  &.StripeElement--complete {
    border-color: $valid-color !important;
  }
  &.StripeElement--complete.StripeElement--focus {
    box-shadow: 0 0 0 0.2rem rgba(80, 159, 146, 0.25);
  }
}
.credit-card-shimmer {
  height: 10rem !important;
}
.input-shimmer {
  & .shimmer-title-line {
    height: 2rem !important;
  }
}
.add-card-link {
  text-decoration: underline !important;
}
.highlight-row {
  background-color: #d9efdc !important;
}
.promo-return-btn {
  border-radius: 0.313rem !important;
  &:hover:enabled {
    box-shadow: none !important;
  }
  &:focus {
    box-shadow: none !important;
  }
}
.border-radius-3 {
  border-radius: 3px !important;
}
.letter-spacing-minus-04 {
  letter-spacing: -0.04rem;
}
.letter-spacing-minus-02 {
  letter-spacing: -0.02rem;
}
.rounded-right-0 {
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.rounded-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
}
.delete-intention-box .modal-content {
  border-radius: 10px;
  border: 2px solid $black;
  background: $white;
  & .border-radius-5 {
    border-radius: 0.313rem !important;
  }
}
