.module-content {
  .icons {
    font-size: 1.5rem;
    transition: font-size 300ms ease;
    span {
      line-height: 1.75rem;
      // &:hover {
      //   font-size: 1.656rem;
      //   transition: font-size 300ms ease;
      // }
    }
  }
}

// override swiper class
.swiper-button-disabled {
  display: none !important;
}
@include media-breakpoint-up(lg) {
  .swiper-button-next {
    right: -2.5rem !important;
  }
  .swiper-button-prev {
    left: -2.5rem !important;
  }
}

@include media-breakpoint-down(md) {
  .swiper-button-next {
    right: 1rem !important;
  }
  .swiper-button-prev {
    left: 1rem !important;
  }
}

.card-swiper {
  & .swiper-slide {
    width: 12.438rem !important;
    //height: 12.438rem !important;
  }
}

.media-player-img {
  width: 100%;
}

.breakthrough {
  position: absolute;
  bottom: 0.5rem;
  right: 0;
  left: 0;
}

.breakthrough-icon {
  font-size: 2.5rem;
}

@include media-breakpoint-up(lg) {
  .breakthrough-container {
    height: 100vh;
  }
  .breakthrough-media {
    // aspect-ratio: 3/1;
    .upgrade-remainder {
      @include transition-all;
      display: none;
    }
    &:hover {
      .upgrade-remainder {
        display: flex;
      }
      .bt-title-card {
        display: none !important;
      }
    }
  }
  .breakthrough-media-card {
    @include transition-all;
    .play-icon {
      display: none;
    }
    .update-text {
      display: none;
    }
    &:hover {
      .update-text {
        display: block;
      }
      .card {
        display: none;
      }
      .play-icon {
        display: block;
      }
    }
  }
  .layout-with-play-bar {
    padding-bottom: 6rem;
    transition: padding-bottom 500ms ease-out;
  }
}

@include media-breakpoint-down(sm) {
  .media-player-img {
    width: auto !important;
    margin-left: -100%;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
}

@include media-breakpoint-down(lg) {
  .breakthrough-media {
    // aspect-ratio: 5/2;
    .upgrade-remainder {
      @include transition-all;
      display: none;
    }
    &:hover {
      .upgrade-remainder {
        display: flex;
      }
      .bt-title-card {
        display: none;
      }
    }
  }
  .breakthrough-media-card {
    @include transition-all;
    .update-text {
      display: none;
    }
    .play-icon {
      display: none;
    }
    &:hover {
      .update-text {
        display: block;
      }
      .card {
        display: none;
      }
      .play-icon {
        display: block;
      }
    }
  }

  .breakthrough-container {
    height: calc(100vh - 45px);
  }
  .breakthrough {
    bottom: 6.5rem !important;
  }
  .breakthrough-icon {
    font-size: 2rem;
  }
  .upgrade-remainder {
    display: flex;
    @include background-opacity($black, 0.75);
  }
  .play-icon {
    display: none;
  }
  .media-card-title {
    line-height: 1rem;
    letter-spacing: -0.04rem;
    margin-top: 0.421rem;
  }
}

// SVG
.download-icon {
  width: fit-content;
  @include transition-all;
  svg {
    fill: none;
    path {
      fill: $white;
    }
  }
  &:hover {
    svg {
      fill: $white;
      path {
        fill: map-get($saffron, '700');
      }
    }
  }
}

.backword-icon-svg:hover,
.forward-icon-svg:hover {
  text,
  path {
    fill: map-get($saffron, '300');
  }
}

@include swiper-buttons(43%);

@include media-breakpoint-up(lg) {
  .play-bar-image {
    width: 64px;
    height: 64px;
    img {
      border-radius: 5px;
    }
    & .img-link {
      display: none;
    }
    &:hover {
      & .img-link {
        display: flex;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .play-bar-image {
    width: 48px;
    height: 48px;
    img {
      border-radius: 5px;
    }
  }
}

.playbar-title {
  width: calc(100% - (96px + 1rem + 1rem));
  a {
    &:first-child {
      line-height: 1.75rem;
    }
  }
}

.favorites-hr {
  padding: 0;
  margin-top: 0.75rem;
  margin-bottom: 2rem;
  border-top: 2px solid $black;
}
.program-wrp .program-img-zoom {
  transition: transform 1s; /* smoother zoom */
}
.program-wrp:hover .program-img-zoom {
  transform: scale(1.2);
  transform-origin: 50% 50%;
}

.space-y-programs {
  > :nth-child(n + 2) {
    margin-top: 2.5rem;
  }
}
.mt-favorite-section > :nth-child(n + 2) {
  margin-top: 3.25rem;
}
.resize-image {
  bottom: 24px;
  right: 24px;
}
.cursor-zoom-in {
  cursor: zoom-in;
}
.cursor-zoom-out {
  cursor: zoom-out;
}
.module-info-hr {
  padding: 0;
  margin: 1.5rem 0;
  border-top: 1px solid $line-separator-color;
}

.z1048 {
  z-index: 1048;
}
@keyframes donut-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.media-player-loader-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 48px;
  height: 48px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-left-color: map-get($saffron, '700');
  border-radius: 50%;
  animation: donut-spin 0.8s linear infinite;
}
.media-player-loader {
  width: 48px;
  height: 48px;
}
.playbar-height {
  min-height: 6.25rem;
  align-items: center;
}
.shimmer-thumbnail-mediaplayer {
  min-width: 100%;
  margin-bottom: auto;
}
.module-info {
  padding: 1.5rem 0.5rem;
  border-bottom: 1px solid $line-separator-color;
  &:hover {
    background: map-get($gray, '100');
    border-bottom: 1px solid transparent;
    border-radius: 0.313rem;
    .module-media-description {
      opacity: 1 !important;
    }
  }
}
.first-media-info {
  border-top: 1px solid $line-separator-color;
}
.poetic-media-info {
  padding: 2rem 1.75rem;
}
