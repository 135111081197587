/* Hide scrollbar for Chrome, Safari and Opera */
.notification-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.notification-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  z-index: 99;
}
.bg-red-800 {
  background: map-get($red, '800');
}
.bg-green-100 {
  background: $valid-color;
}
.notification-container {
  .toast:not(:last-child) {
    margin-bottom: 0.75rem;
  }
}
@include media-breakpoint-up(lg) {
  .notification-container {
    position: fixed;
    top: 1rem;
    right: 1rem;
    max-height: 100vh;
    height: 'auto';
    overflow: auto;
    width: 300px;
    z-index: 1100;
  }
}
@include media-breakpoint-down(lg) {
  .notification-container {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    left: 1rem;
    overflow: auto;
    z-index: 1100;
  }
}
