.border-first:first-child .border-second {
  border-radius: 5px 0 0 5px;
}
.border-first:last-child .border-second {
  border-radius: 0 5px 5px 0;
}

.cursor-hover {
  border-bottom: 1px solid #f3f6f6;
  &:hover {
    background-color: #f3f6f6;
    .font-hover {
      font-weight: 700;
    }
  }
}
.unfocus textarea {
  &:focus-visible {
    outline: none;
  }
}

.table-hover {
  &.table-width {
    margin: auto;
  }
  tbody tr td {
    border: none !important;
    .w-20 {
      width: 20%;
    }
    .w-80 {
      width: 80%;
    }
  }
  @media (max-width: 426px) {
    tbody,
    th,
    td,
    .w-20 {
      width: 35%;
    }
    .w-80 {
      width: 65%;
    }
  }
  @media (max-width: 376px) {
    tbody,
    th,
    td,
    .w-20 {
      width: 40%;
    }
    .w-80 {
      width: 60%;
    }
  }
}

.numerical-msg {
  background-color: map-get($saffron, '300');
  color: white;
  width: 1rem;
  height: 1rem;
}

.bhutan-text {
  margin-left: 4.375rem;
}

.custom-right-left {
  .swiper-button-next {
    right: 1.5rem !important;
    top: 8rem !important;
  }
  .swiper-button-prev {
    left: 1.5rem !important;
    top: 8rem !important;
  }
}

@media (min-width: 1440px) {
  .custom-right-left {
    .swiper-button-next {
      right: 2.5rem !important;
      top: 8rem !important;
    }
    .swiper-button-prev {
      left: 2.5rem !important;
      top: 8rem !important;
    }
  }
}
@media (min-width: 2560px) {
  .custom-right-left {
    .swiper-button-next {
      right: 0.5rem !important;
      top: 6rem !important;
    }
    .swiper-button-prev {
      left: 0.5rem !important;
      top: 6rem !important;
    }
  }
  .bhutan-text {
    margin-left: 1.375rem;
  }
}
@media (max-width: 1025px) {
  .bhutan-text {
    margin-left: 0.375rem;
  }
}

@media (max-width: 426px) {
  .img-100 {
    width: 100% !important;
  }

  .custom-right-left {
    .swiper-button-next {
      right: 0.5rem !important;
    }
    .swiper-button-prev {
      left: 0.5rem !important;
    }
  }
}
.imgix-object-cover {
  object-fit: cover;
}
.pre {
  white-space: pre-wrap;
  word-break: break-word;
}
.blog-short-discription,
.blog-details p {
  font-family: 'Open Sans';
  font-weight: 500;
  font-size: 1rem; // 16px
  line-height: 1.75rem; // 28px
  letter-spacing: -0.02rem; // -0.32px
}

.blog-details {
  h1,
  h2,
  h3 {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 1.5rem; // 24px
    line-height: 2rem; // 32px
    letter-spacing: -0.11rem; // -1.76px
    margin-bottom: 1rem;
  }
  hr {
    margin-top: 1.875rem !important; // 30px
    margin-bottom: 2.25rem !important; // 36px
  }
  p {
    margin-bottom: 1rem;
  }
}
