.custom-saffron-btn {
  font-size: 0.75rem;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.15em;
  color: white;
  text-align: center;
  padding: 0.75rem 1.5rem;
  opacity: 1;
  background: map-get($saffron, '300');
  border-radius: 0.313rem;
  width: fit-content;
}
.custom-gray-info-text {
  padding: 1.875rem 2.375rem;
  border-radius: 0.625rem;
  border: 2px solid $black;
  background: map-get($gray, '100');
  cursor: default;
}
.unlock-card {
  padding: 2rem auto;
  text-align: center;
  border-radius: 0.625rem;
  border: 1px solid $black;
  background: $abroad-blue;
}
.free-plan-without-survey-image {
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
  background-color: #d9d9d9;
}
.img-hover-zoom {
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
  img {
    transition: transform 1s; /* smoother zoom */
  }
  &:hover img {
    transform: scale(1.2);
    transform-origin: 50% 50%;
  }
}
.begin-page:hover {
  img {
    transition: transform 1s; /* smoother zoom */
  }
  &:hover img {
    transform: scale(1.2);
    transform-origin: 50% 50%;
  }
  &:hover {
    .begin-journey-section {
      .icon-right-arrow,
      .begin-text {
        color: map-get($saffron, '300') !important;
      }
    }
  }
}
.color-right-arrow {
  color: map-get($saffron, '300') !important;
}
@include media-breakpoint-up(lg) {
  .free-plan-without-survey-image {
    aspect-ratio: 6/3;
  }
}
@include media-breakpoint-only(md) {
  .free-plan-without-survey-image {
    aspect-ratio: 6/4.75;
  }
}
@include media-breakpoint-down(md) {
  .free-plan-without-survey-image {
    //aspect-ratio: 23/20 !important;
    height: 9.188rem !important;
  }
}
