@each $prop, $abbrev in (font-size: font) {
  @each $size, $length in $font-sizes {
    .#{$abbrev}-#{$size} {
      #{$prop}: $length !important;
    }
  }
  @each $breakpoint in sm, md, lg {
    @include media-breakpoint-up($breakpoint) {
      @each $size, $length in $font-sizes {
        .#{$abbrev}-#{$breakpoint}-#{$size} {
          #{$prop}: $length !important;
        }
      }
    }
  }
}

@each $name, $color in $colors {
  @each $prop, $opacity in $opacities {
    .bg-#{$name} {
      &.bg-opacity-#{$prop} {
        @include background-opacity($color, $opacity);
      }
    }
    .hover\:bg-#{$name} {
      &:hover {
        @include transition-all;
        &.hover\:bg-opacity-#{$prop} {
          @include background-opacity($color, $opacity);
        }
      }
    }
  }
}

@each $name, $colors in $custom-colors {
  @each $number, $color in $colors {
    .font-#{$name}-#{$number} {
      color: $color;
    }
  }
}

@each $name, $color in $colors {
  .font-#{$name} {
    color: $color;
  }
}

@each $prop, $opacity in $opacities {
  .opacity-#{$prop} {
    opacity: $opacity;
  }
}

@each $prop, $abbrev in (x: x) {
  @each $size, $length in $customsize {
    .space-#{$prop}-#{$size} {
      > :nth-child(n + 2) {
        margin-left: $length;
        // display: inline;
      }
    }
  }
  @each $breakpoint in sm, md, lg {
    @include media-breakpoint-up($breakpoint) {
      @each $size, $length in $customsize {
        .space-#{$breakpoint}-#{$prop}-#{$size} {
          > :nth-child(n + 2) {
            margin-left: $length !important;
          }
        }
      }
    }
  }
}

@each $prop, $abbrev in (y: y) {
  @each $size, $length in $customsize {
    .space-#{$prop}-#{$size} {
      > :nth-child(n + 2) {
        margin-top: $length;
      }
    }
  }
  @each $breakpoint in sm, md, lg {
    @include media-breakpoint-up($breakpoint) {
      @each $size, $length in $customsize {
        .space-#{$breakpoint}-#{$prop}-#{$size} {
          > :nth-child(n + 2) {
            margin-top: $length;
          }
        }
      }
    }
  }
}

@each $prop, $abbrev in (x: x) {
  @each $size, $length in $customsize {
    .space-#{$prop}-#{$size} {
      > :nth-child(n + 2) {
        margin-left: $length;
        // display: inline;
      }
    }
  }
  @each $breakpoint in sm, md, lg {
    @include media-breakpoint-up($breakpoint) {
      @each $size, $length in $customsize {
        .space-#{$breakpoint}-#{$prop}-#{$size} {
          > :nth-child(n + 2) {
            margin-left: $length !important;
          }
        }
      }
    }
  }
}

@each $prop, $abbrev in (y: y) {
  @each $size, $length in $customsize {
    .space-#{$prop}-#{$size} {
      > :nth-child(n + 2) {
        margin-top: $length;
      }
    }
  }
  @each $breakpoint in sm, md, lg {
    @include media-breakpoint-up($breakpoint) {
      @each $size, $length in $customsize {
        .space-#{$breakpoint}-#{$prop}-#{$size} {
          > :nth-child(n + 2) {
            margin-top: $length;
          }
        }
      }
    }
  }
}
