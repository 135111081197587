.intro-notes {
  border-radius: 10px;
  border: 2px solid $black;
  background-color: map-get($gray, '100');
  padding: 2rem;

  & .notes {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
.journey-tabs {
  .shimmer-button {
    margin-top: 1rem !important;
    margin-right: 1.5rem !important;
    margin-bottom: 0 !important;
  }
}
.media-details {
  & .audio-sub-title {
    padding-inline-start: 0rem;
    margin-inline-start: 1rem;
  }
}
.text-dark-900-5 {
  color: map-get($dark, '900-5');
}

// group-journey dropdown
.group-journey-dropdown {
  .active {
    .dropdown-toggle {
      color: $white;
      background-color: $abroad-blue;
      border: 1px solid $abroad-blue !important;
    }
  }
  .dropdown-menu {
    margin-top: 0.5rem;
    background-color: $abroad-blue !important;
    padding: 1.188rem 1.5rem;
    border-radius: 0.313rem;
    border: 2px solid $white;
  }
  .dropdown-toggle {
    text-transform: uppercase;
    color: $black;
    padding: 0.625rem 1.5rem;
    text-align: center;
    margin-right: 1rem;
    border: 1px solid $black;
    border-radius: 0.313rem;
    opacity: 1;
    @extend .s9;
    &.active,
    &:hover {
      color: $white;
      background-color: $abroad-blue;
      border: 1px solid $abroad-blue !important;
    }
    &:focus-visible,
    &.active {
      outline: none !important;
    }
    &:disabled,
    &.disabled {
      opacity: 0.5 !important;
      pointer-events: none;
    }
  }
  // CSS to hide the default caret icon
  .dropdown-toggle::after {
    display: none;
  }
  .dropdown-item {
    color: $white !important;
    margin-bottom: 0.75rem;
    &:hover,
    &.active,
    &:active {
      text-decoration: none !important;
      color: map-get($saffron, '300') !important;
      font-weight: 700;
      background-color: $abroad-blue !important;
    }
  }
}
@include media-breakpoint-up(lg) {
  .group-journey-dropdown {
    .dropdown-menu {
      width: 19.313rem !important;
    }
  }
}

@include media-breakpoint-down(lg) {
  .group-journey-dropdown {
    .dropdown-menu {
      width: 17rem !important;
    }
  }
}

@include media-breakpoint-down(md) {
  .group-journey-dropdown {
    .dropdown-menu {
      width: 14.375rem !important;
    }
  }
}
