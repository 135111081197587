.custom-table {
  .icon-play {
    display: none;
  }
  .font-bold {
    font-weight: bold !important;
  }
  &.table-responsive {
    margin: 0 -8px;
    padding: 0 8px;
    width: calc(100% + 16px);
  }
  & tbody tr:hover {
    background-color: map-get($gray, '100') !important;
    td {
      font-weight: 700;
    }
    .hover\:td-play-icon {
      & span:first-child {
        display: none;
      }
      & .icon-play {
        display: block;
      }
    }
  }
  &.table-hover {
    tbody {
      tr {
        &:hover {
          tr {
            background-color: map-get($gray, '100') !important;
            td {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
  &.table {
    border: none;
    margin-bottom: 0px;

    tr,
    th,
    td {
      border: none;
      background-color: unset;
      color: $black;
    }

    thead {
      tr th {
        @extend .s10a;
        border-bottom: 1px solid $black;
        text-align: left;
        padding: 0rem 1.063rem 0.75rem 1.063rem;
        &.text-right {
          padding-right: 0px;
        }
      }
    }

    tbody {
      tr {
        td {
          @extend .s8;
          border-top: none;
          border-bottom: 1px solid map-get($gray, '100');
          padding: 1rem;
          text-align: left;
          vertical-align: middle;
          a {
            color: map-get($saffron, '700');
            text-decoration: none;

            &:hover {
              font-weight: 700;
            }
          }
          .right-aligned.form-group > div {
            text-align: right;
          }
        }
      }
    }
  }
}
