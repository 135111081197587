.signup-left-section {
  margin-top: 9.5rem;
}
.signup-invite-link {
  margin-top: 6.5rem;
}
.signup {
  .signup-form {
    width: 100%;
    height: auto;
    transition: 1s;
  }
}

.name-fields {
  .form-group {
    width: calc(50% - 10px);
  }
}
.onboard {
  .form-group {
    margin-bottom: 2.5rem;
  }
}
.password-suggestion {
  background: #f3f6f6;
  border: 1px solid $invalid-color;
  padding: 0.625rem 1rem;
}
.password-suggestion-menu {
  transform: translate(0px, -130px) !important;
}
.return-link {
  position: relative;
  opacity: 0.5;
  color: #fff;
  & .return-text {
    margin-left: 0.625rem;
  }
}
@include media-breakpoint-down(lg) {
  .password-suggestion-menu {
    transform: translate(0px, -105px) !important;
  }
}
@include media-breakpoint-only(md) {
  .signup-form {
    max-width: 21rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@include media-breakpoint-down(md) {
  @media (orientation: landscape) {
    .signup-form {
      max-width: 21rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  @media (orientation: portrait) {
    .signup-form {
      max-width: 100%;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}
