.custom-swiper {
  .swiper-button-prev {
    left: 93% !important;
    top: -0.5% !important;
    &::before {
      content: '\e916';
      font-family: 'icon';
      color: #bf9000;
      font-size: 15px;
    }
    &.swiper-button-disabled {
      display: block !important;
      &::before {
        content: '\e916';
        font-family: 'icon';
        color: #000000;
        font-size: 15px;
      }
    }
  }

  .swiper-button-next {
    top: -0.5% !important;
    left: 96.3% !important;
    &::before {
      content: '\e917';
      font-family: 'icon';
      color: #bf9000;
      font-size: 15px;
    }
    &.swiper-button-disabled {
      display: block !important;
      &::before {
        content: '\e917';
        font-family: 'icon';
        color: #000000;
        font-size: 15px;
      }
    }
  }

  @media (max-width: 768px) {
    .swiper-button-prev {
      left: 90% !important;
    }
    .swiper-button-next {
      left: 95% !important;
    }
  }

  @media (max-width: 425px) {
    .swiper-button-prev {
      left: 85% !important;
    }
    .swiper-button-next {
      left: 90% !important;
    }
  }

  @media (max-width: 320px) {
    .swiper-button-prev {
      left: 80% !important;
    }
    .swiper-button-next {
      left: 85% !important;
    }
  }
}
.custom-swiper-arrow {
  .swiper-button-prev {
    left: 95% !important;
    top: -1% !important;
  }

  .swiper-button-next {
    top: -1% !important;
    left: 100% !important;
  }
  @media (max-width: 768px) {
    .swiper-button-prev {
      left: 90% !important;
    }
    .swiper-button-next {
      left: 95% !important;
    }
  }
}
