#content {
  margin-top: 3.6rem;
}
.sidebar-content {
  @include gray-scrollbar;
}
@include media-breakpoint-down(lg) {
  .custom-pr-sm-0 {
    padding-right: 0;
  }
  .custom-pl-sm-0 {
    padding-left: 0;
  }
}
@include media-breakpoint-down(lg) {
  .pt-sm-sidebar {
    padding-top: 3.7rem !important;
  }
}
@include media-breakpoint-up(lg) {
  .sidebar-content {
    z-index: 100;
    position: fixed !important;
    top: 0;
    left: 0;
    bottom: 0;
  }
  .content-gutter {
    z-index: -1;
  }
}
@include media-breakpoint-down(lg) {
  #content {
    width: 100vw;
  }
  .sidebar-content {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    @include transition(left 0.25s ease-out);
    &.hide {
      left: -100vw;
    }
  }
}
