@import './variables.scss';
.font-open-sans {
  font-family: 'Open Sans';
}
.font-montserrat {
  font-family: 'Montserrat' !important;
}
.font-cormorant-garamond {
  font-family: 'Cormorant Garamond' !important;
}

.s1 {
  font-family: 'Cormorant Garamond';
  font-weight: 500;
  font-size: 2.75rem; // 44px
  line-height: 3.5rem; // 56px
  letter-spacing: -0.11rem; // -1.76px
}

.s2 {
  font-family: 'Cormorant Garamond';
  font-weight: 500;
  font-size: 2.375rem; // 38px
  line-height: 3.25rem; // 52px
  letter-spacing: -0.095rem; // -1.52px
}

.s3 {
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 1.5rem; // 24px
  line-height: 2.375rem; //  38px
  letter-spacing: -0.03rem; // -0.48px
}

.s4 {
  font-family: 'Cormorant Garamond';
  font-weight: 500;
  font-size: 1.875rem; // 30px
  line-height: 2.5rem; // 40px
  letter-spacing: -0.075rem; // -1.2px
}

.s5 {
  font-family: 'Open Sans';
  font-weight: 500;
  font-size: 1.125rem; // 18px
  line-height: 2rem; // 32px
  letter-spacing: -0.023rem; // -0.36px
}

.s6 {
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 1rem; // 16px
  line-height: 1.625rem; // 26px
  letter-spacing: -0.02rem; // -0.32px
}

.s6a {
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 1rem; // 16px
  line-height: 1.75rem; // 28px
  letter-spacing: -0.02rem; // -0.32px
}

.s6b {
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 0.875rem; // 14px
  line-height: 1.5rem; // 24px
  letter-spacing: -0.02rem; // -0.32px
}

.s6c {
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 1.25rem; // 20px
  line-height: 1.625rem; // 26px
}

.s7 {
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 0.938rem; // 15px
  line-height: 1.625rem; // 26px
  letter-spacing: -0.019rem; // -0.32px
}
.s8 {
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 0.813rem; // 13px
  line-height: 1.25rem; // 20px
  letter-spacing: 0;
}

.s9 {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 0.75rem; // 12px
  letter-spacing: 0.15rem; // 2.4px
  line-height: normal;
  text-transform: uppercase;
}
.s9a {
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 0.75rem; // 12px
  letter-spacing: 0.05rem; // 2.4px
  line-height: normal;
}
.s10a {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 0.625rem; // 10px
  line-height: normal;
  letter-spacing: 0.125rem; // 2px
  text-transform: uppercase;
}
.s10b {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 0.625rem; // 10px
  letter-spacing: 0.125rem; // 2px
  line-height: normal;
  text-transform: uppercase;
}
.s10c {
  @extend .s10a;
  line-height: 1rem; // 16px
}
.s11 {
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 0.75rem; // 12px
  line-height: normal;
  letter-spacing: 0rem;
}
.s12 {
  font-family: 'Cormorant Garamond';
  font-size: 1.875rem; // 30px
  font-style: normal;
  font-weight: 500;
  line-height: 2.5rem; // 40px
  letter-spacing: -0.075rem; // -1.2px
}
.s13 {
  font-family: 'Open Sans';
  font-size: 1.125rem; // 18px
  font-style: normal;
  font-weight: 400;
  line-height: 1.875rem; // 30px
  letter-spacing: -0.023rem; // -0.36px
}
.s14 {
  font-family: Open Sans;
  font-size: 1.5rem; // 24px
  font-style: normal;
  font-weight: 600;
  line-height: 2rem; // 32px
  letter-spacing: -0.03rem; // -0.48px
}
.s-captions {
  font-family: 'Open Sans';
  font-size: 0.625rem; // 10px
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.s-small-description {
  font-family: 'Open Sans';
  font-size: 0.75rem; //12px
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem; //20px
}

.bg-image-none {
  background-image: none !important;
}
a {
  color: map-get($saffron, '300');
  text-decoration: none;
  &:hover {
    text-decoration: underline !important;
  }
}
.form-label {
  color: rgba(255, 255, 255, 0.7);
}
.font-16px {
  font-size: 1rem;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600 !important;
}
.line-height-40px {
  line-height: 2.5rem;
}
// checkbox style
input[type='checkbox'] {
  height: 1.25rem;
  width: 1.25rem;
  top: 0.35rem;
  color: $white;
  + label {
    &::after,
    &::before {
      height: 1.25rem;
      width: 1.25rem;
      cursor: pointer;
    }
    &:hover {
      &::before {
        border-color: rgb(0, 0, 0) !important;
      }
    }
    &::after {
      top: 0.35rem;
    }
    &::before {
      border-color: rgba(0, 0, 0, 0.7);
      top: 0.35rem;
      border-radius: 5px;
    }
  }
}
.quest-border-color {
  border: 1px solid #0a223c;
}
// custom checkbox style
.custom-checkbox .form-check-input:checked[type='checkbox'] {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M3.23567 6.37384C3.17334 6.43651 3.08834 6.47151 3.00001 6.47151C2.91167 6.47151 2.82667 6.43651 2.76434 6.37384L0.88234 4.49151C0.687007 4.29618 0.687007 3.97951 0.88234 3.78451L1.11801 3.54884C1.31334 3.35351 1.62967 3.35351 1.82501 3.54884L3.00001 4.72384L6.17501 1.54884C6.37034 1.35351 6.68701 1.35351 6.88201 1.54884L7.11767 1.78451C7.31301 1.97984 7.31301 2.29651 7.11767 2.49151L3.23567 6.37384Z' fill='white'/%3e%3c/svg%3e ") !important;
  background-color: $valid-color !important;
  border: 1px solid $valid-color !important;
  background-image: none;
  border-radius: 5px;
  &:focus {
    box-shadow: none !important;
  }
}
.custom-checkbox .form-check-input:focus {
  box-shadow: none !important;
}
.password-visibility-icon {
  top: 0.35rem;
  right: 1rem;
  z-index: 1;
  cursor: pointer;
}
.form-control-lg {
  height: 2.5rem;
  min-height: 2.5rem;
}
.border-radius-5 {
  border-radius: 0.313rem;
}
.text-green-100 {
  color: $valid-color;
}
.text-abroad-blue {
  color: $abroad-blue;
}
.bg-gray-300 {
  background: map-get($gray, '300');
}
.text-gray-800 {
  color: map-get($gray, '800');
}
.text-gray-201 {
  color: map-get($gray, '201');
}
.text-gray-501 {
  color: map-get($gray, '501');
  .next-module,
  .previous-module {
    path {
      stroke: map-get($gray, '501');
    }
  }
}
// custom border radius
.border-radius-10 {
  border-radius: 0.625rem !important;
}
.border-radius-50 {
  border-radius: 3.125rem !important;
}
.border-radius-full {
  border-radius: 10rem;
}
.border-none {
  border: none;
}
// position absolute properties
.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.inset-0 {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

// custom colors/ background-colors
.bg-abroad-blue {
  background-color: $abroad-blue;
}

.cursor-pointer {
  cursor: pointer;
}

.color-black {
  color: $black;
}
.custom-gray-bg-100 {
  background: map-get($gray, '100');
}
.font-38px {
  font-size: 2.375rem;
}
.text-white {
  color: $white !important;
}
.text-black {
  color: $black !important;
}
.text-decoration-none {
  text-decoration: none !important;
  &:hover {
    text-decoration: none !important;
  }
}
.icon-play-light:before {
  content: '\e90d';
  color: #fff;
}
.abroad-table-border {
  border-color: map-get($gray, '100');
}
.abroad-table th {
  font-weight: inherit;
  padding-bottom: 0.75rem !important;
}
.table {
  --bs-table-hover-bg: #f3f6f6;
}

.ab-hover:hover td {
  font-weight: 700;
}
.upgrade-now {
  padding: 2rem 5.875rem;
  border: 1px solid $black;
}
.icon-outline-gold:before {
  color: map-get($saffron, '700');
}
.learning-coaching {
  padding: 2rem 1.5rem 2rem 2rem;
}
.learn-more-image {
  border-top-right-radius: 0.313rem;
  border-bottom-right-radius: 0.313rem;
}
.begin-section {
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}
.learn-more-details {
  flex-basis: 80%;
}
.text-saffron-700 {
  color: map-get($saffron, '700');
}
.text-saffron-700-5 {
  color: map-get($saffron, '700-5');
}
.font-bold-700 {
  font-weight: 700;
}
.cursor-disabled {
  cursor: not-allowed;
}
@include media-breakpoint-up(lg) {
  .w-lg-30 {
    width: 30% !important;
  }
  .w-36rem {
    width: 36rem !important;
  }
}
@include media-breakpoint-down(lg) {
  .upgrade-now {
    padding: 1.5rem 1rem;
  }
  .border-radius-full {
    border-radius: 0.625rem;
  }
}
@include media-breakpoint-down(md) {
  .learning-coaching {
    padding: 1.5rem 2rem;
  }
  .begin-journey-section {
    width: 100%;
  }
}
.pointer-events-none {
  pointer-events: none;
}

.form-control::placeholder {
  @extend .s1;
  opacity: 0.5;
}

.ab-hover {
  td,
  td .font-700 {
    &::after {
      display: block;
      content: attr(title);
      font-weight: 700;
      color: transparent;
      height: 1px;
      margin-bottom: -1px;
      visibility: hidden;
      overflow: hidden;
    }
  }
}

.media-table-hover:hover {
  .sr-no-hover {
    position: relative;
  }

  .sr-no-hover::before {
    content: '\25B6';
    color: map-get($saffron, '700');
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1rem;
    transform: translate(-50%, -50%);
    opacity: 1;
  }

  .sr-no-hover .sr-number {
    opacity: 0;
  }
}

.modal-content {
  border-radius: 10px;
  border: 2px solid $black;
}

.modal-body {
  padding: 1.25rem 1.5rem;
}

.bg-abroad-blue {
  color: $abroad-blue;
}
.pointer-event-none {
  pointer-events: none;
}
.z20 {
  z-index: 20;
}
.hover\:text-saffron-700 {
  &:hover {
    color: map-get($saffron, '700') !important;
  }
}
.hover\:bg-gray-100 {
  &:hover {
    background: map-get($gray, '100') !important;
  }
}
.text-blue-600 {
  color: map-get($blue, '600') !important;
}
.text-green-500 {
  color: map-get($green, '500') !important;
}
.upgrade-inquiry {
  @include gray-scrollbar;
  .inquiry-title {
    font-family: Open Sans;
    font-size: 1.25rem; //20px
    font-style: normal;
    font-weight: 600;
    line-height: 2.125rem; //34px
    letter-spacing: -0.025rem; //-0.4px
  }
  .email-body-textarea {
    border: 1.5px solid $black;
    background: $white;
    color: map-get($dark, '900-7');
    padding: 2rem 2.813rem;
  }
}
@include media-breakpoint-down(xl) {
  .email-body-textarea {
    overflow: scroll;
  }
}
.subcategory-audio-card {
  margin-bottom: 3.688rem;
}
@include media-breakpoint-down(md) {
  .subcategory-audio-card {
    margin-bottom: 1rem;
  }
}
.overflow-xy {
  overflow: visible !important;
}
.overflow-x {
  overflow-x: hidden !important;
}
