.simple-360 {
  @include gray-scrollbar;

  .request-wrapper {
    overflow-y: scroll;
    max-height: 30rem !important;
  }

  .green-checkmark {
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='11' height='11' rx='5.5' fill='green' stroke='green'/%3E%3Cpath d='M5.23567 8.37384C5.17334 8.43651 5.08834 8.47151 5.00001 8.47151C4.91167 8.47151 4.82667 8.43651 4.76434 8.37384L2.88234 6.49151C2.68701 6.29618 2.68701 5.97951 2.88234 5.78451L3.11801 5.54884C3.31334 5.35351 3.62967 5.35351 3.82501 5.54884L5.00001 6.72384L8.17501 3.54884C8.37034 3.35351 8.68701 3.35351 8.88201 3.54884L9.11767 3.78451C9.31301 3.97984 9.31301 4.29651 9.11767 4.49151L5.23567 8.37384Z' fill='white'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
  .preview-email {
    b {
      font-weight: 700 !important;
    }
  }
}
.custom-container {
  .font-weight-300 {
    p {
      font-weight: 300 !important;
    }
  }
  .grid-box {
    .btn-saffron,
    .btn-outline-dark {
      font-weight: 300 !important;
    }
    .custom-numeric-btn {
      font-size: 1.1rem;
    }
  }
  .custom-question-size {
    font-size: 1.875rem;
    line-height: 2.2rem;
  }
}
@media (min-width: 1200px) {
  .custom-container {
    max-width: 700px !important;
    .custom-question-size {
      width: 700px !important;
    }
  }
  .custom-previous-next {
    .previous-btn {
      &:hover,
      &:active,
      &:focus-visible {
        background-color: map-get($saffron, '300');
        border: 1px solid $black;
        color: $black;
      }
    }
    .next-btn:hover {
      background-color: map-get($saffron, '700');
      border: 1px solid $black;
      color: $black;
    }
    .next-btn {
      &:active,
      &:focus-visible {
        background-color: map-get($saffron, '300');
        border: 1px solid map-get($saffron, '300');
        color: $black;
      }
    }
  }
}
@media (max-width: 1200px) {
  .custom-container {
    max-width: 700px !important;
    .custom-question-size {
      width: 700px !important;
    }
  }
  .custom-previous-next {
    .previous-btn {
      &:hover,
      &:active,
      &:focus-visible {
        background-color: map-get($saffron, '300');
        border: 1px solid $black;
        color: $black;
      }
    }
    .next-btn:hover {
      background-color: map-get($saffron, '700');
      border: 1px solid $black;
      color: $black;
    }
    .next-btn {
      &:active,
      &:focus-visible {
        background-color: map-get($saffron, '300');
        border: 1px solid map-get($saffron, '300');
        color: $black;
      }
    }
  }
}
@media (max-width: 992px) {
  .custom-container {
    max-width: 600px !important;
    .custom-question-size {
      width: 600px !important;
    }
  }
  .custom-previous-next {
    .previous-btn {
      &:hover,
      &:active,
      &:focus-visible {
        background-color: transparent;
        border: 1px solid $white;
        color: $white;
      }
    }
    .next-btn {
      &:hover,
      &:active,
      &:focus-visible {
        background-color: map-get($saffron, '300');
        border: 1px solid map-get($saffron, '300');
        color: $black;
      }
    }
  }
}
@media (max-width: 768px) {
  .custom-container {
    max-width: 450px !important;
    .custom-question-size {
      width: 450px !important;
    }
  }
  .custom-previous-next {
    .previous-btn {
      &:hover,
      &:active,
      &:focus-visible {
        background-color: transparent;
        border: 1px solid $white;
        color: $white;
      }
    }
    .next-btn {
      &:hover,
      &:active,
      &:focus-visible {
        background-color: map-get($saffron, '300');
        border: 1px solid map-get($saffron, '300');
        color: $black;
      }
    }
  }
}
@media (max-width: 576px) {
  .custom-container {
    max-width: 100% !important;
    .custom-question-size {
      width: 100% !important;
    }
  }
  .custom-previous-next {
    .previous-btn {
      &:hover,
      &:active,
      &:focus-visible {
        background-color: transparent;
        border: 1px solid $white;
        color: $white;
      }
    }
    .next-btn {
      &:hover,
      &:active,
      &:focus-visible {
        background-color: map-get($saffron, '300');
        border: 1px solid map-get($saffron, '300');
        color: $black;
      }
    }
  }
}

@media (max-width: 350px) {
  .custom-container {
    margin-top: 30px;
  }
  .grid-box {
    width: 80%;
  }
}
