.page-bottom-link {
  position: relative;
  @extend .s8;
}
.login-error {
  background: #e9c5c5;
  border: none;
  @extend .s8;
}
.login-error-wrapper {
  padding: 1rem 1.5rem;
  border-radius: 5px;
  border: 1px solid $invalid-color;
  background: #e9c5c5;
  box-shadow: 2px 4px 8px 0px rgba(0, 0, 0, 0.25);
}
.reset-password-link {
  margin-top: -0.3rem;
}
