.bg-gray-100 {
  background-color: map-get($gray, '100') !important;
}

// journal icon
.journal-icon:hover {
  path {
    stroke: map-get($saffron, '700');
  }
}
.journal-icon.active {
  .journel-line {
    stroke: $white;
  }
}

// profile icon
.profile-icon-wrapper {
  &:hover {
    .user-profile-icon {
      path {
        stroke: map-get($saffron, '700') !important;
      }
    }
  }
  &.active {
    .user-profile-icon {
      fill: map-get($saffron, '700') !important;
      path {
        stroke: map-get($saffron, '700') !important;
      }
    }
  }
}

.header-drop-down {
  border-radius: 5px;
  border: 1.5px solid $black;
  background: $white;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.dropdown-item-text {
  font-family: Open Sans;
  font-size: 0.875rem; //14px
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; //24px
  letter-spacing: -0.018rem; //-0.28px
}

// profile section
.profile-dropdown {
  transform: translate(0px, 12px) !important;
  left: auto !important;
  right: 0;
  padding: 1.5rem 1.563rem;
  .dropdown-item {
    color: #000 !important;
    background-color: transparent !important;
    padding: 0rem 0rem 1.125rem 0rem;
    @extend .dropdown-item-text;
    text-decoration: none !important;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    font-weight: 700;
  }
  .dropdown-item:hover {
    font-weight: 700;
  }
}

// ask-question section
.ask-question {
  .form-control {
    border-radius: 0.313rem;
    border: 1.5px solid $black;
    background: $white;
    &.is-invalid {
      background-image: none !important;
      border: 1.5px solid $invalid-color !important;
      &:focus {
        border: 1.5px solid $invalid-color !important;
        box-shadow: 0 0 0 0.25rem rgba($invalid-color, 0.25);
      }
    }
    &.is-valid {
      background-image: none !important;
      border: 1.5px solid $valid-color !important;
      &:focus {
        border: 1.5px solid $valid-color !important;
        box-shadow: 0 0 0 0.25rem rgba($valid-color, 0.25);
      }
    }
  }
}

// notification section
.notification-icon-wrapper {
  &:hover {
    .notification-icon {
      path {
        stroke: map-get($saffron, '700') !important;
      }
    }
    .notification-circle {
      display: none;
    }
  }
  &.active {
    .notification-icon {
      fill: map-get($saffron, '700') !important;
      path {
        stroke: map-get($saffron, '700') !important;
      }
      circle {
        display: none;
      }
    }
    .notification-circle {
      display: none;
    }
  }
  .notification-circle {
    border-radius: 50%;
    background: map-get($saffron, '700');
    height: 8px;
    width: 8px;
  }
}
.notification-text {
  font-family: Open Sans;
  font-size: 0.875rem; //14px
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; //24px
  letter-spacing: -0.018rem; //0.28px
}
.notification-title {
  font-family: Montserrat;
  font-size: 0.625rem; //10px
  font-style: normal;
  font-weight: 600;
  line-height: 1rem; //16px
  letter-spacing: 0.125rem; //2px
}
.notification-section {
  .notification-img {
    margin-right: 1.188rem;
  }
  .notification-message {
    @extend .notification-text;
    flex-basis: 100%;
  }
  .notification-time {
    width: 32px;
    text-align: right;
  }
  .notification-link:hover {
    .notification-message {
      font-weight: 700;
    }
  }
}

.notification-wrapper {
  @include customize-scrollbar;
  & .notification-section {
    max-height: 16.5rem;
    overflow-y: overlay !important;
    overflow-y: scroll;
  }
}

@include media-breakpoint-up(lg) {
  .notification-dropdown {
    width: 400px;
    left: auto !important;
    right: 0;
    transform: translate(35px, 12px) !important;
  }
}

@include media-breakpoint-down(lg) {
  .sidebar-close-dropdown {
    &.notification-dropdown {
      left: auto;
      right: 0;
      transform: translate(45px, 12px) !important;
    }
  }
  .sidebar-open-dropdown {
    &.notification-dropdown {
      left: auto;
      right: 0;
      transform: translate(90px, 12px) !important;
    }
  }
}

// enroll in program section
.enroll-btn {
  @extend .s10a;
  padding: 0.625rem 1.5rem !important;
}

.go-back-icon {
  margin-top: -5px;
  z-index: 2;
  svg {
    &:first-child {
      display: inline;
    }
    &:last-child {
      display: none;
    }
  }
  &:hover {
    svg {
      &:first-child {
        display: none;
      }
      &:last-child {
        display: inline;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .enroll-btn {
    font-size: 0.5rem !important;
    padding: 0.5rem 0.75rem !important;
  }
}
