// cookie popup
.react-cookie-law-dialog {
  bottom: 0 !important;
  top: unset !important;
  background-color: #000 !important;
}

.react-cookie-law-manage-btn,
.react-cookie-law-save-btn,
.react-cookie-law-accept-btn {
  font-family: 'Montserrat' !important;
  border: none !important;
  border-radius: 5px !important;
  margin: 5px !important;
  padding: 5px 15px !important;
  text-transform: capitalize !important;
}

.react-cookie-law-manage-btn {
  color: map-get($saffron, '300') !important;
  outline: none !important;
  &:hover {
    color: map-get($saffron, '600') !important;
  }
}

.react-cookie-law-accept-btn,
.react-cookie-law-save-btn {
  color: '#fff' !important;
  font-weight: 500;
  background-color: map-get($saffron, '300') !important;
  outline: none !important;
  &:hover {
    background-color: map-get($saffron, '600') !important;
  }
}
