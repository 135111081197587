.grecaptcha-badge {
  visibility: hidden;
}
.onboard-wrapper {
  max-width: 21rem;
  width: 100%;
}
.signup-logo {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}
.onboard {
  & .form-control {
    box-shadow: none !important;
  }
  & .form-control.is-invalid {
    background-image: none !important;
    padding-right: 0.75rem;
  }
  & .form-control {
    border: 2px solid map-get($gray, '101');
    background: rgba(243, 246, 246, 0.1);
    background-color: rgba(243, 246, 246, 0.1);
    color: rgba(255, 255, 255, 0.7);
    font-family: Open Sans !important;
    font-weight: 600 !important;
    font-size: 0.875rem !important;
    line-height: 1.5rem !important;
    letter-spacing: -0.02em !important;
    // height: calc(1.5em + 0.75rem);
  }
  & .form-control.is-invalid {
    border: 2px solid rgba(178, 34, 34, 0.7);
  }

  & .form-control.is-invalid:focus {
    border: 2px solid $invalid-color;
  }
  .was-validated .form-control:valid,
  .form-control.is-valid {
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='11' height='11' rx='5.5' fill='%23509F92' stroke='%23509F92'/%3E%3Cpath d='M5.23567 8.37384C5.17334 8.43651 5.08834 8.47151 5.00001 8.47151C4.91167 8.47151 4.82667 8.43651 4.76434 8.37384L2.88234 6.49151C2.68701 6.29618 2.68701 5.97951 2.88234 5.78451L3.11801 5.54884C3.31334 5.35351 3.62967 5.35351 3.82501 5.54884L5.00001 6.72384L8.17501 3.54884C8.37034 3.35351 8.68701 3.35351 8.88201 3.54884L9.11767 3.78451C9.31301 3.97984 9.31301 4.29651 9.11767 4.49151L5.23567 8.37384Z' fill='white'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
  & .was-validated .form-control:valid:focus,
  .form-control.is-valid:focus {
    border: 2px solid map-get($gray, '100');
    box-shadow: none;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
  input[data-autocompleted] {
    background-color: transparent !important;
  }
  & input:-webkit-autofill {
    border: 2px solid map-get($gray, '101');
    opacity: 0.7;
    -webkit-text-fill-color: rgba(255, 255, 255, 0.7);
    color: rgba(255, 255, 255, 0.7);
  }
  & input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    color: #fff;
  }
  // & input:-webkit-autofill,
  // input:-webkit-autofill:hover,
  // input:-webkit-autofill:focus {
  //   border: 2px solid map-get($gray, '101') !important;
  //   background: rgba(243, 246, 246, 0.1) !important;
  //   background-color: rgba(243, 246, 246, 0.1) !important;
  //   color: #fff !important;
  // }
  & .input-field-group {
    input:focus {
      border: 2px solid map-get($gray, '100');
      color: $white !important;
      + .form-label {
        color: $white !important;
        font-weight: 700 !important;
      }
    }
  }
  // Hide the eye icon from a password input in MS Edge and IE
  input[type='password']::-ms-reveal,
  input[type='password']::-ms-clear {
    display: none;
  }
}
.abroad-logo {
  margin-top: 3rem;
}
.signup-logo {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}
.text-error {
  color: map-get($red, '800');
}
.onbording-action-btn {
  margin-top: 3.25rem;
}
.two-fa-otp-error {
  border: 2px solid $invalid-color !important;
}
.two-fa-otp-input input {
  width: 2.75rem !important;
  height: 2.75rem !important;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 3px;
  border: 2px solid #fff;
  background: map-get($gray, '102');
  background-color: map-get($gray, '102');
  margin-right: 1rem;
  outline: none !important;
  box-shadow: none !important;
  &:focus-visible {
    border-radius: 3px;
    border: 2px solid $white;
  }
  &:last-child {
    margin-right: 0;
  }
  &:focus {
    outline: none !important;
  }
}
.otp-screen {
  margin-top: 2rem;
}
.twofa-setup {
  & .left-section {
    max-width: 26.438rem;
    width: 100%;
  }
  & .qr-code {
    width: 10rem;
    height: 10rem;
  }
}
.text-red-100 {
  color: map-get($red, '100');
}
.is-invalid-password {
  border: 1px solid $invalid-color;
}
.is-valid-password {
  border: 1px solid $valid-color;
}
@include media-breakpoint-up(lg) {
  .h-lg-65 {
    height: 65%;
  }
}
@include media-breakpoint-only(md) {
  .two-fa-otp-input input {
    width: 2.5rem !important;
    height: 2.5rem !important;
    margin-right: 8px;
  }
  .onboard-wrapper {
    max-width: 21rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@include media-breakpoint-down(md) {
  .two-fa-otp-input input {
    width: 2rem !important;
    height: 2rem !important;
  }
  @media (orientation: landscape) {
    .onboard-wrapper {
      max-width: 21rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  @media (orientation: portrait) {
    .onboard-wrapper {
      max-width: 100%;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

.resize-none {
  resize: none;
}
textarea:focus-visible {
  outline: none;
  box-shadow: none;
}
.custom-border {
  border: 1px solid #fff;
  opacity: 0.5;
}
textarea {
  border-radius: 5px;
  background: #121212;
  color: #e2e2e2;
  border: none;
  display: block;
  width: 100%;
  resize: none;
  line-height: 1.4;
  overflow: hidden;
  height: auto;
  padding: 8px;
  font-size: 18px;
  outline: none;
}
input:focus-visible {
  outline: none !important;
  box-shadow: none !important;
}

.grid-box {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, minmax(min(16.6667%, 48px), 1fr));
  gap: 9px;
}
