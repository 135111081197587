.home-link {
  color: map-get($saffron, '700');
  font-weight: 600;
  &:hover {
    font-weight: 700;
  }
}
.tracking-md-wide {
  letter-spacing: 0.1em;
}
.tracking-lg-wide {
  letter-spacing: 0.2em;
}
.not-found,
.error-view {
  .return-link,
  .report-link {
    opacity: 1 !important;
    color: map-get($saffron, '600');
    &:hover {
      color: map-get($saffron, '700');
    }
  }
}

.generating-results {
  max-width: 26rem;
  width: 100%;
  .bt-spinner {
    width: 4rem !important;
    height: 4rem !important;
  }
}

// session page

.download-icon-rotate {
  animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@include media-breakpoint-up(lg) {
  .not-found {
    .home-link {
      position: relative;
      top: 4.5rem;
    }
    .under-maintenance {
      .home-link {
        position: relative;
        top: 4.5rem;
      }
    }
    .w-lg-35 {
      width: 35%;
    }
  }
  .h-lg-100 {
    height: 100%;
  }
}

@include media-breakpoint-down(sm) {
  .not-found {
    .w-sm-100 {
      width: 100% !important;
    }
  }
}
@include media-breakpoint-down(lg) {
  .not-found {
    .w-sm-100 {
      width: 100% !important;
    }
    .home-link {
      position: absolute;
      bottom: 32px;
      left: 0;
      right: 0;
      text-align: center;
    }
  }
}
