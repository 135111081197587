.mt-40px {
  margin-top: 2.5rem !important;
}
.mb-40px {
  margin-bottom: 2.5rem !important;
}
.mt-32px {
  margin-top: 2rem !important;
}
.mb-32px {
  margin-bottom: 2rem !important;
}
.mb-12px {
  margin-bottom: 0.75rem !important;
}
.mt-12px {
  margin-top: 0.75rem;
}
.my-12px {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.my-16px {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.mb-25px {
  margin-bottom: 1.56rem !important;
}
.mb-sm-25px {
  margin-bottom: 1.56rem !important;
}
.mt-52px {
  margin-top: 3.25rem !important;
}
.mb-52px {
  margin-bottom: 3.25rem !important;
}
.mt-64px {
  margin-top: 4rem !important;
}
.ml-40px {
  margin-left: 2.5rem;
}
.mt-15px {
  margin-top: 0.938rem;
}
.mb-20px {
  margin-bottom: 1.25rem;
}
.mt-20px {
  margin-top: 1.25rem;
}
.mb-26px {
  margin-bottom: 1.62rem;
}
.mb-3px {
  margin-bottom: 0.188rem;
}
.mx-12px {
  margin: 0 0.75rem;
}
.mb-6px {
  margin-bottom: 0.375rem;
}
.mb-14px {
  margin-bottom: 0.875rem;
}
.mt-13px {
  margin-top: 0.813rem;
}
.ml-12px {
  margin-left: 0.75rem;
}
.mr-12px {
  margin-right: 0.75rem;
}
.mt-minus-04 {
  margin-top: -0.4rem;
}
.mb-35px {
  margin-bottom: 2.188rem;
}
.mb-18px {
  margin-bottom: 1.125rem;
}
.mb-42px {
  margin-bottom: 2.625rem;
}
.mt-30px {
  margin-top: 1.875rem;
}
.mb-4rem {
  margin-bottom: 4rem;
}
.pb-12px {
  padding-bottom: 0.75rem !important;
}
.mb-38px {
  margin-bottom: 2.375rem !important;
}
.mb-64px {
  margin-bottom: 4rem !important;
}
.py-12px {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}
.px-50px {
  padding-left: 3.125rem;
  padding-right: 3.125rem;
}
.pl-32px {
  padding-left: 2rem;
}
.pr-32px {
  padding-left: 2rem;
}
.pt-32px {
  padding-top: 2rem;
}
.pb-32px {
  padding-bottom: 2rem;
}
.p-32px {
  padding: 2rem;
}
.mr-32px {
  margin-right: 2rem;
}
.ml-32px {
  margin-left: 2rem;
}
.mb-36px {
  margin-bottom: 2.25rem;
}
.ml-20px {
  margin-left: 1.25rem;
}
.mt-18px {
  margin-top: 1.125rem;
}
.mr-25px {
  margin-right: 1.563rem;
}
.mt-16px {
  margin-top: 1rem;
}
.mb-16px {
  margin-bottom: 1rem;
}
.py-16px {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pl-16px {
  padding-left: 1rem;
}
.pt-16px {
  padding-top: 1rem;
}
.pr-16px {
  padding-right: 1rem;
}
.pr-29px {
  padding-right: 1.813rem;
}
.mt-24px {
  margin-top: 1.5rem;
}
.mb-24px {
  margin-bottom: 1.5rem !important;
}
.mt-44px {
  margin-top: 2.75rem;
}
.py-40px {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.px-36px {
  padding-left: 2.25rem !important;
  padding-right: 2.25rem !important;
}
.py-14px {
  padding-top: 0.875rem !important;
  padding-bottom: 0.875rem !important;
}
.mt-80px {
  margin-top: 80px;
}
.mr-24px {
  margin-right: 24px;
}

.mt-120px {
  margin-top: 120px;
}
.mt-6px {
  margin-top: 0.375rem;
}
.mr-10px {
  margin-right: 0.625rem;
}
.mt-10px {
  margin-top: 0.625rem;
}
.mb-62px {
  margin-bottom: 3.875rem;
}
.mb-75px {
  margin-bottom: 4.688rem;
}
// todo: remove below tracking css
.tracking-normal {
  letter-spacing: 0;
}
.tracking-md-wide {
  letter-spacing: 0.1rem;
}
.tracking-lg-wide {
  letter-spacing: 0.2rem;
}
.tracking-lg-wide-point-25 {
  letter-spacing: 0.25rem !important;
}

.leading-3 {
  line-height: 0.75rem !important;
}
.leading-4,
.leading-none {
  line-height: 1rem;
}
.leading-5 {
  line-height: 1.25rem;
}
.leading-6 {
  line-height: 1.5rem;
}
.leading-7 {
  line-height: 1.75rem;
}
.leading-7-half {
  line-height: 1.875rem;
}
.leading-8 {
  line-height: 2rem;
}
.leading-9 {
  line-height: 2.25rem;
}
.leading-10 {
  line-height: 2.5rem;
}
.leading-tight {
  line-height: 1.25rem;
}
.leading-snug {
  line-height: 1.375rem;
}
.leading-normal {
  line-height: 1.5rem;
}
.leading-relaxed {
  line-height: 1.625rem;
}
.leading-52px {
  line-height: 3.25rem;
}
.line-height-normal {
  line-height: normal !important;
}
.abroad-table > :not(caption) > * > * {
  padding: 0px 0px;
}
@include media-breakpoint-up(lg) {
  .mt-lg-80px {
    margin-top: 5rem !important;
  }
  .pl-lg-32px {
    padding-left: 2rem;
  }
  .mb-lg-12px {
    margin-bottom: 0.75rem;
  }
}
@include media-breakpoint-down(lg) {
  .mt-40px {
    margin-top: 2rem !important;
  }
  .mb-40px {
    margin-bottom: 2rem !important;
  }
  .mt-32px {
    margin-top: 1.5rem !important;
  }
  .mb-32px {
    margin-bottom: 1.5rem !important;
  }
  .mt-52px {
    margin-top: 3.25rem !important;
  }
  .mb-52px {
    margin-bottom: 3.25rem !important;
  }
  .ml-40px {
    margin-left: 2rem;
  }
  .mb-35px {
    margin-bottom: 1.625rem;
  }
  .mb-42px {
    margin-bottom: 2.1rem;
  }
  .pl-32px {
    padding-left: 1.5rem;
  }
  .pr-32px {
    padding-left: 1.5rem;
  }
  .pt-32px {
    padding-top: 1.5rem;
  }
  .pb-32px {
    padding-bottom: 1.5rem;
  }
  .p-32px {
    padding: 1.5rem;
  }
  .mr-32px {
    margin-right: 1.5rem;
  }
  .ml-32px {
    margin-left: 1.5rem;
  }
  .mb-36px {
    margin-bottom: 1.75rem;
  }
}
