.survey-comparison {
  .font-montserrat {
    font-family: 'Montserrat', 'Open Sans';
  }
  .font-semibold {
    font-weight: 700;
  }
  .archived-intention {
    border-bottom: 4px solid $black;
  }
  .w-8\/12 {
    width: 66.66667%;
  }
  .table {
    color: #212529;
  }
  .results-date {
    font-family: 'Montserrat', 'Open Sans';
    color: #000;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
  }
  .font-normal {
    font-weight: 400 !important;
  }
  .bg-gray-101 {
    background-color: #f9f9f9;
  }
  .gaugeSmaller {
    width: 100%;
    height: 150px;
    display: block;
  }
  .result-category-compare-panel {
    border-top: 1px solid;
    border-bottom: 1px solid;
  }
  .result-category-compare-label {
    font-family: 'Montserrat', 'Open Sans';
    font-weight: 400;
    line-height: 1.5;
    font-size: 23pt;
  }
  .table-sm td,
  .table-sm th {
    padding: 0.3rem;
  }
  .assessment-date {
    max-width: 100px;
  }
  .result-compare-circle {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    margin: 0 auto;
  }
  .text-gray-201 {
    color: #706f6f;
  }
  .comparison-table {
    font-size: 13pt;
    font-family: 'Open Sans';
    &.table {
      th {
        vertical-align: top;
        border-top: 1px solid #dee2e6;
      }
      tbody {
        font-weight: 300;
        & tr:hover {
          background-color: map-get($gray, '100') !important;
        }
      }
    }
  }
  .btn-black {
    line-height: normal !important;
    font-family: Montserrat !important;
    font-weight: 600 !important;
    font-size: 0.625rem !important;
    letter-spacing: 0.25em !important;
    text-transform: uppercase !important;
    background-color: $black;
    color: $white;
    letter-spacing: 0.25rem;
    text-align: center;
    border-radius: 5px;
    padding: 8px 30px;
    &:hover {
      background-color: map-get($dark, '800');
      color: $white;
    }
    &:disabled {
      background-color: map-get($dark, '600');
      color: $white;
    }
    &:hover:enabled {
      box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.3);
    }
  }
  .highcharts-credits {
    display: none;
  }
  .bg-green-100 {
    background-color: #509f92;
  }
  .text-green-100 {
    color: #509f92;
  }
  .bg-green-200 {
    background-color: #37763b;
  }
  .text-green-200 {
    color: #37763b;
  }
  .bg-red-500 {
    background-color: #bc4c6a;
  }
  .bg-red-700 {
    background-color: #7e2d55;
  }
  .text-red-500 {
    color: #bc4c6a;
  }
  .text-red-700 {
    color: #7e2d55;
  }
  .text-gray-800 {
    color: #a0a0a0 !important;
  }
  .text-blue-100 {
    color: map-get($blue, '100') !important;
  }
  .bg-blue-100 {
    background-color: map-get($blue, '100');
  }
  .text-blue-200 {
    color: map-get($blue, '200') !important;
  }
  .bg-blue-200 {
    background-color: map-get($blue, '200');
  }
  @include media-breakpoint-up(lg) {
    .w-lg-9\/12 {
      width: 75% !important;
    }
    .font-lg-44 {
      font-size: 2.75rem;
    }
  }
  @include media-breakpoint-down(lg) {
    .assessment-date {
      max-width: 50%;
    }
  }
}
