.download-app-steps {
  margin-left: 5rem;
}
.download-app-image-wrp {
  width: 15rem;
}
@include media-breakpoint-down(lg) {
  .download-app-steps {
    margin: 0;
    margin-top: 1rem;
  }
}
